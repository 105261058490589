









































































































import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  Ref,
} from "@vue/composition-api";
import { publicControllerApi } from "@/core/api/publicController.api";
import { MediaCollectionItem } from "@/core/dataTypes/types";
import { preloadImage } from "@/core/utils/preloadImage.util";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "SlideShowComponent",
  props: {
    interval: {
      type: Number,
      default: 5000,
    },
    cycle: {
      type: Boolean,
      default: false,
    },
    dataListId: {
      type: String,
      required: true,
    },
    bottomControlOnMobile: {
      type: Boolean,
      default: false,
    },
    coverImage: {
      type: Boolean,
      default: true,
    },
    imageLayerVisible: {
      type: Boolean,
      default: true,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    useLandscapeFormat: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const dataList: Ref<MediaCollectionItem[]> = ref([]);
    const startStop: Ref<boolean> = ref(props.cycle);
    const activeItem: Ref<number> = ref(0);
    const linkList: Ref<string[]> = ref([]);

    onMounted(() => {
      nextTick(() => {
        const navigationButtonPrev = document.querySelector(".v-window__prev");
        (navigationButtonPrev?.firstElementChild as HTMLElement).setAttribute(
          "aria-label",
          i18n.tc("SLIDESHOW.ACCESSIBILITY.PREVIOUS")
        );
        const navigationButtonNext = document.querySelector(".v-window__next");
        (navigationButtonNext?.firstElementChild as HTMLElement).setAttribute(
          "aria-label",
          i18n.tc("SLIDESHOW.ACCESSIBILITY.NEXT")
        );
      });
    });

    const slideStopStart = () => {
      startStop.value = !startStop.value;
    };

    const changeCarousel = (activeMedia: number) => {
      activeItem.value = activeMedia;
    };

    const openImageOnFullscreen = () => {
      const link = linkList.value[activeItem.value];
      if (link) {
        window.open(link);
      }
    };

    publicControllerApi
      .getMediaCollection(process.env.VUE_APP_TENANT, props.dataListId)
      .then((response) => {
        response.items.forEach((item: MediaCollectionItem) => {
          if (item.media.mime.startsWith("image")) {
            preloadImage(
              props.useLandscapeFormat
                ? (item.media.thumbnails?.landscape[2] as string)
                : item.media.url
            );
          }
        });
        dataList.value = response.items;
        linkList.value = response.items.map(
          (i: MediaCollectionItem) => i.media.url
        );
      });

    return {
      dataList,
      startStop,
      slideStopStart,
      changeCarousel,
      openImageOnFullscreen,
    };
  },
});

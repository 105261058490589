import { Content } from "@/core/dataTypes/types";
import store from "@/store";

const newsLoading = (): boolean | undefined => store.state.home?.newsLoading;
const getNewsList = (): Promise<void> => store.dispatch("home/getNewsList");
const highlightedNews = (): Content[] | undefined =>
  store.state.home?.highlightedNews;
const simpleNews = (): Content[] | undefined => store.state.home?.simpleNews;

const otherLanguageContent = (): Content | undefined =>
  store.state.home?.otherLanguageContent;
const otherLanguageContentLoading = (): boolean | undefined =>
  store.state.home?.otherLanguageContentLoading;
const getOtherLanguageContent = (): Promise<void> =>
  store.dispatch("home/getOtherLanguageContent");

export const homeFacade = {
  newsLoading,
  getNewsList,
  highlightedNews,
  simpleNews,
  otherLanguageContent,
  otherLanguageContentLoading,
  getOtherLanguageContent,
};














































import { computed, defineComponent, ref } from "@vue/composition-api";
import { commonFacade } from "@/store/modules/common/common.facade";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";

export default defineComponent({
  name: "CookieAlert",
  components: {
    HTMLContentRenderer,
  },
  setup(props, { emit }) {
    const cookieContent = computed(commonFacade.cookieContent);
    const cookieContentLoading = computed(commonFacade.cookieContentLoading);
    const showBottomSheet = ref(true);
    commonFacade.getCookieContent();

    const acceptCookie = () => {
      localStorage.setItem("nuszCookieAcceptedDate", new Date().toISOString());
      emit("cookieAlertAccepted");
    };

    return {
      acceptCookie,
      cookieContent,
      cookieContentLoading,
      dialog: showBottomSheet,
    };
  },
});

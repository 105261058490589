import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";
import { Content, CustomerServiceDetail } from "@/core/dataTypes/types";
import store from "@/store";

const getCustomerServices = (): Promise<void> =>
  store.dispatch("customerServices/getCustomerServices");
const customerServices = (): CMSMapPoint[] | undefined =>
  store.state.customerServices?.customerServices;
const customerServicesLoading = (): boolean | undefined =>
  store.state.customerServices?.customerServicesLoading;
const customerServicesReset = (): Promise<void> =>
  store.dispatch("customerServices/customerServicesReset");

const getCustomerServiceDetail = (id: string): Promise<void> =>
  store.dispatch("customerServices/getCustomerServiceDetail", id);
const customerServiceDetail = (): CustomerServiceDetail | undefined =>
  store.state.customerServices?.customerServiceDetail;
const customerServiceDetailLoading = (): boolean | undefined =>
  store.state.customerServices?.customerServiceDetailLoading;
const resetCustomerServiceDetail = (): Promise<void> =>
  store.dispatch("customerServices/resetCustomerServiceDetail");

const customerServicesContent = (): Content | undefined =>
  store.state.customerServices?.customerServicesContent;
const customerServicesContentLoading = (): boolean | undefined =>
  store.state.customerServices?.customerServicesContentLoading;
const getCustomerServicesContent = (slug: string): Promise<void> =>
  store.dispatch("customerServices/getCustomerServicesContent", slug);

export const customerServicesFacade = {
  getCustomerServices,
  customerServices,
  customerServicesLoading,
  customerServicesReset,
  resetCustomerServiceDetail,
  getCustomerServiceDetail,
  customerServiceDetail,
  customerServiceDetailLoading,
  customerServicesContent,
  customerServicesContentLoading,
  getCustomerServicesContent,
};










































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import FaqTab from "@/views/FAQ/FaqTab/FaqTab.vue";
import { faqFacade } from "@/store/modules/faq/faq.facade";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import router, { useRoute } from "@/router";
import { EFaqTabTypes } from "@/core/dataTypes/enums";

export default defineComponent({
  name: "FAQPage",
  components: {
    FaqTab,
    HTMLContentRenderer,
  },
  props: {
    activeTab: {
      type: String,
      default: EFaqTabTypes.E_MATRICA,
    },
  },
  setup(props) {
    const route = useRoute();
    const baseContent = computed(faqFacade.baseContent);
    const eMatricaFaqList = computed(faqFacade.eMatricaFaqList);
    const eMatricaFaqListLoading = computed(faqFacade.eMatricaFaqListLoading);
    const eUtdijFaqList = computed(faqFacade.eUtdijFaqList);
    const eUtdijFaqListLoading = computed(faqFacade.eUtdijFaqListLoading);
    const tab = ref(
      router.currentRoute.query?.tab
        ? router.currentRoute.query.tab
        : props.activeTab
    );

    const slug = ref(router.currentRoute.query?.slug);
    const eMatricaIndex = ref(
      eMatricaFaqList.value?.findIndex((item) => item.slug === slug.value)
    );
    const eUtdijIndex = ref(
      eUtdijFaqList.value?.findIndex((item) => item.slug === slug.value)
    );

    watch(tab, () => {
      router.replace({
        query: {
          tab: tab.value,
        },
      });
    });

    const init = () => {
      faqFacade.resetModule();
      faqFacade.getBaseContent(route.value.slug as string);

      faqFacade.getEMatricaFaqList();
      faqFacade.getEUtdijFaqList();
    };

    init();

    watch(route, (curr, prev) => {
      if (curr.path !== prev.path) {
        init();
        tab.value = props.activeTab;
      }
    });

    return {
      baseContent,
      eMatricaFaqList,
      eMatricaFaqListLoading,
      eUtdijFaqList,
      eUtdijFaqListLoading,
      tab,
      EFaqTabTypes,
      eMatricaIndex,
      eUtdijIndex,
    };
  },
  metaInfo() {
    const title: string =
      (this.baseContent?.seoTitle as string) ??
      (this.baseContent?.title as string);
    const description = this.baseContent?.seoLead as string;
    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
    };
  },
});

import Vue from "vue";
import DialogWrapper from "@/components/dialog/DialogWrapper.vue";
import AlertDialog from "@/components/dialog/AlertDialog.vue";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
import {
  AlertDialogPropsType,
  DialogOptionsType,
  DialogResultType,
  ConfirmDialogPropsType,
} from "../dataTypes/types";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";
import { uuid } from "../utils/uuid.util";

class Dialog {
  dialog: Vue;

  constructor(dialogOptions: DialogOptionsType) {
    const ComponentClass = Vue.extend(DialogWrapper);
    this.dialog = new ComponentClass({
      vuetify,
      i18n,
      propsData: dialogOptions,
    });

    this.dialog.$mount();
    this._handleDialogCloseEvent();
    this._handleDialogResultEvent();
  }

  dialogUnmounted(callback: () => void): void {
    this.dialog.$on("dialogUnmounted", callback);
  }

  private _destroyDialog(): void {
    this.dialog.$destroy();
  }

  private _handleDialogCloseEvent(): void {
    this.dialog.$on("dialogClose", (): void => this._destroyDialog());
  }

  private _handleDialogResultEvent(): void {
    this.dialog.$on("dialogResult", (result: DialogResultType): void => {
      if (result && result.autoClose) {
        this._destroyDialog();
      }
    });
  }
}

class DialogService {
  dialogInstances: Vue[] = [];

  open<T = unknown>(dialogOptions: Omit<DialogOptionsType<T>, "id">): Vue {
    const dialog = new Dialog({
      ...dialogOptions,
      id: uuid(),
    });
    dialog.dialogUnmounted(() => this._removeDialogInstance(dialog.dialog));
    this.dialogInstances.push(dialog.dialog);

    accessibilityFacade.setOpenedDialogCount(this.getDialogInstancesCount());

    return dialog.dialog;
  }

  getDialogInstancesCount(): number {
    return this.dialogInstances.length;
  }

  alertDialog(
    dialogOptions: Omit<
      DialogOptionsType<AlertDialogPropsType>,
      "component" | "id"
    >
  ): Vue {
    return this.open({ ...dialogOptions, component: AlertDialog });
  }

  confirmDialog(
    dialogOptions: Omit<
      DialogOptionsType<ConfirmDialogPropsType>,
      "component" | "id"
    >
  ): Vue {
    return this.open({ ...dialogOptions, component: ConfirmDialog });
  }

  private _removeDialogInstance(dialog: Vue): void {
    this.dialogInstances = this.dialogInstances.filter((i) => i !== dialog);
    accessibilityFacade.setOpenedDialogCount(this.getDialogInstancesCount());
  }

  closeAll(): void {
    if (this.dialogInstances.length) {
      this.dialogInstances.map((dialog) => {
        dialog.$destroy();
      });
      accessibilityFacade.setOpenedDialogCount(this.getDialogInstancesCount());
    }
  }
}

export default new DialogService();

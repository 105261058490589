import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { commonFacade } from "./store/modules/common/common.facade";
import VueCompositionAPI from "@vue/composition-api";
import vuetify from "./plugins/vuetify";
import httpService from "./core/services/http.service";
import VueGtag from "vue-gtag";
import VueMeta from "vue-meta";
import i18n from "./plugins/i18n";
import "@/core/utils/validation";
import "@/core/utils/captcha";
import { formatCurrency, formatDate } from "@/core/filters";
import { initRecaptcha } from "@/core/utils/captcha";

Vue.config.productionTip = false;
Vue.filter("formatDate", formatDate);
Vue.filter("formatCurrency", formatCurrency);
Vue.use(VueCompositionAPI);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

const initializeApp = async () => {
  await Promise.all([
    commonFacade.getKeyValuePairs(),
    commonFacade.getTenant(),
    commonFacade.getContentCategories(),
  ]);
  commonFacade.resetMenu();
  commonFacade.resetContactContent();
  commonFacade.resetCookieContent();

  Vue.use(VueGtag, {
    config: { id: commonFacade.keyValuePairs()?.GTMKey.value },
  });

  const vm = new Vue({
    store,
    router,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
  initRecaptcha(vm);
};

initializeApp();

window.onbeforeunload = () => {
  if (httpService.isPending()) {
    httpService.cancel();
  }
};

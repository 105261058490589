import { Content, CustomerServiceDetail } from "@/core/dataTypes/types";
import { publicControllerApi } from "@/core/api/publicController.api";
import { AppState } from "@/store";
import { Module } from "vuex";
import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";
import { publicHugoCustomerServiceControllerApi } from "@/core/api/publicHugoCustomerServiceController.api";

export interface CustomerServicesState {
  customerServicesContent: Content;
  customerServicesContentLoading: boolean;
  customerServices: CMSMapPoint[];
  customerServicesLoading: boolean;

  customerServiceDetail: CustomerServiceDetail;
  customerServiceDetailLoading: boolean;
}

export const initialCustomerServices: CustomerServicesState = {
  customerServicesContent: {} as Content,
  customerServicesContentLoading: false,
  customerServices: [],
  customerServicesLoading: false,

  customerServiceDetail: {} as CustomerServiceDetail,
  customerServiceDetailLoading: false,
};

export const customerServicesModule: Module<CustomerServicesState, AppState> = {
  namespaced: true,
  state: initialCustomerServices,
  mutations: {
    customerServicesReset: (state) => {
      state.customerServices = [];
    },
    customerServicesRequest: (state) => {
      state.customerServicesLoading = true;
    },
    customerServicesSuccess: (state, payload: CMSMapPoint[]) => {
      state.customerServices = payload;
      state.customerServicesLoading = false;
    },
    customerServicesFail: (state) => {
      state.customerServicesLoading = false;
    },

    customerServiceDetailRequest: (state) => {
      state.customerServiceDetailLoading = true;
    },
    customerServiceDetailSuccess: (state, payload: CustomerServiceDetail) => {
      state.customerServiceDetail = payload;
      state.customerServiceDetailLoading = false;
    },
    customerServiceDetailFail: (state) => {
      state.customerServiceDetailLoading = false;
    },
    resetCustomerServiceDetail: (state) => {
      state.customerServiceDetail = {} as CustomerServiceDetail;
    },

    customerServicesContentRequest: (state) => {
      state.customerServicesContentLoading = true;
    },
    customerServicesContentSuccess: (state, payload: Content) => {
      state.customerServicesContent = payload;
      state.customerServicesContentLoading = false;
    },
    customerServicesContentFail: (state) => {
      state.customerServicesContentLoading = false;
    },
  },

  actions: {
    getCustomerServices: ({ commit, rootState }) => {
      commit("customerServicesRequest");
      return publicHugoCustomerServiceControllerApi
        .getCustomerServices(
          process.env.VUE_APP_TENANT,
          rootState.common?.currentLanguage as string
        )
        .then((response) => {
          commit("customerServicesSuccess", response);
        })
        .catch((error) => {
          commit("customerServicesFail");
          throw Error(error);
        });
    },

    customerServicesReset: ({ commit }) => {
      commit("customerServicesReset");
    },

    getCustomerServiceDetail: ({ commit }, id: string) => {
      commit("customerServiceDetailRequest");
      return publicHugoCustomerServiceControllerApi
        .getCustomerServiceDetail(process.env.VUE_APP_TENANT, id)
        .then((response) => {
          console.log("getCustomerServiceDetail", response);
          commit("customerServiceDetailSuccess", response);
        })
        .catch((error) => {
          commit("customerServiceDetailFail");
          throw Error(error);
        });
    },

    resetCustomerServiceDetail: ({ commit }) => {
      commit("resetCustomerServiceDetail");
    },

    getCustomerServicesContent: ({ commit }, slug: string) => {
      commit("customerServicesContentRequest");
      return publicControllerApi
        .getMenuContentByPublicRoute(slug)
        .then((response) => {
          commit("customerServicesContentSuccess", response[0]);
        })
        .catch((error) => {
          commit("customerServicesContentFail");
          throw Error(error);
        });
    },
  },
};

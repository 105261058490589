

















































import { EMenuTypes } from "@/core/dataTypes/enums";
import { commonFacade } from "@/store/modules/common/common.facade";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "MenuListItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    disableGroupItem: {
      type: Boolean,
      default: false,
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    ripple: {
      type: Boolean,
      default: false,
    },
    groupIsOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const currentLanguage = computed(commonFacade.currentLanguage);

    const clickedOnMenuItem = () => {
      emit("clickedOnMenuItem");
    };

    return {
      EMenuTypes,
      currentLanguage,
      clickedOnMenuItem,
    };
  },
});

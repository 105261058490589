import { commonFacade } from "@/store/modules/common/common.facade";

export const baseLanguages: string[] = ["hu", "de", "en"];

export const pageOnBaseLanguage = (): boolean => {
  const currentLanguage = commonFacade.currentLanguage();
  if (currentLanguage) {
    return baseLanguages.includes(currentLanguage);
  } else {
    return false;
  }
};

interface TranslatedLink {
  hu: string;
  en: string;
  de: string;
}

export const ematricaLoginLinks: TranslatedLink = {
  hu: "belepes",
  en: "log-in",
  de: "einloggen",
};

export const ematricaRegistrationLinks: TranslatedLink = {
  hu: "regisztracio",
  en: "registration",
  de: "registrierung",
};

export const ematricaBuyLinks: TranslatedLink = {
  hu: "matricavasarlas",
  en: "vignette-purchase",
  de: "e-vignetten-kauf",
};

export const ematricaUrlWithLanguageCode = (): string => {
  const currLang = commonFacade.currentLanguage() as string;

  return baseLanguages.includes(currLang)
    ? `${process.env.VUE_APP_EMATRICA_URL}/${currLang}`
    : `${process.env.VUE_APP_EMATRICA_URL}/en`;
};

export const ematricaLink = (slug: TranslatedLink): string | undefined => {
  const currLang = commonFacade.currentLanguage();
  let translatedSlug: string;

  if (!currLang) {
    return;
  }

  switch (currLang) {
    case "hu":
      translatedSlug = slug.hu;
      break;
    case "de":
      translatedSlug = slug.de;
      break;
    default:
      translatedSlug = slug.en;
  }

  return `${ematricaUrlWithLanguageCode()}/${translatedSlug}`;
};

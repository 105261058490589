












































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import SocialIconList from "@/components/socialIconList/SocialIconList.vue";
import { commonFacade } from "@/store/modules/common/common.facade";
import { EMenuTypes } from "@/core/dataTypes/enums";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import getYear from "date-fns/getYear";
import axios, { AxiosInstance } from "axios";

export default defineComponent({
  name: "Footer",
  components: {
    SocialIconList,
    HTMLContentRenderer,
  },
  setup() {
    const menu = computed(commonFacade.menu);
    const currentLang = computed(commonFacade.currentLanguage);
    const contactContent = computed(commonFacade.contactContent);
    const contactContentLoading = computed(commonFacade.contactContentLoading);
    const actualYear = getYear(new Date());
    // const appVersion = process.env.VUE_APP_VERSION_INFORMATION;
    const appVersion = ref("");
    const http: AxiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    commonFacade.getContactContent();

    onMounted(() => {
      http.get("/PublicService/public/version/NUD").then((response) => {
        appVersion.value = response.data;
      });
    });
    return {
      actualYear,
      menu,
      EMenuTypes,
      currentLang,
      contactContent,
      contactContentLoading,
      appVersion,
    };
  },
});

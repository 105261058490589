










































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "LoginCard",
  props: {
    logoUrl: {
      type: String,
      required: true,
    },
    logoAltText: String,
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    subDescription: {
      type: String,
      required: true,
    },
    loginButtonText: {
      type: String,
      required: true,
    },
    loginButtonLink: {
      type: String,
      required: true,
    },
    cartButtonText: {
      type: String,
      required: true,
    },
    cartButtonLink: {
      type: String,
      required: true,
    },
    sectionName: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {};
  },
});








































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import ContentCard from "@/components/contentCard/ContentCard.vue";
import router, { useRoute } from "@/router";
import { newsFacade } from "@/store/modules/news/news.facade";
import { tagFacade } from "@/store/modules/tag/tag.facade";
import { Content } from "@/core/dataTypes/types";
import { isEqual } from "lodash/fp";
import handleNavigationDuplicated from "@/core/utils/errorHandlers/handleNavigationDuplicated";
import { CMSTagType } from "@/core/dataTypes/cmsTypes";

export default defineComponent({
  name: "NewsListPage",
  components: {
    ContentCard,
  },
  setup() {
    const newsContentList = computed(newsFacade.newsContentList);
    const newsContentListPageCount = computed(
      newsFacade.newsContentListPageCount
    );
    const content: Ref<Content | undefined> = computed(
      newsFacade.newsListPageContent
    );
    const tags: Ref<CMSTagType[] | undefined> = computed(tagFacade.tags);

    const route = useRoute();
    const page: Ref<number> = ref(1);
    const filters: Ref<string[]> = ref([]);

    const pageValue = (p: string | undefined): number =>
      typeof p === "string" ? +p : 1;

    const pageFilters = (f: string | undefined): string[] =>
      f?.split(",") ?? [];

    if (route.value.query.page || route.value.query.filters) {
      page.value = pageValue(route.value.query?.page as string);
      filters.value = pageFilters(route.value.query?.filters as string);
    }

    newsFacade.getNewsListPageContent(route.value.slug as string);

    newsFacade.getNewsContentList(page.value, filters.value);
    tagFacade.getTags();

    watch(route, (curr) => {
      const currPage = pageValue(curr.query?.page as string);
      const currFilters = pageFilters(curr.query?.filters as string);

      filters.value = currFilters;
      page.value = currPage;

      newsFacade.getNewsContentList(currPage, currFilters);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    watch([page, filters], ([currPage, currFilter], [oldPage, oldFilter]) => {
      if (!isEqual(currFilter, oldFilter) && currPage !== 1) {
        page.value = 1;
      } else {
        const filtersString = filters.value.join(",");
        router
          .replace({
            query: {
              page: `${page.value}`,
              ...(filtersString && {
                filters: filtersString,
              }),
            },
          })
          .catch(handleNavigationDuplicated);
      }
    });

    return {
      content,
      newsContentList,
      newsContentListPageCount,
      page,
      tags,
      filters,
    };
  },
  metaInfo() {
    const title: string =
      (this.content?.seoTitle as string) ?? (this.content?.title as string);
    const description = this.content?.seoLead as string;
    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
    };
  },
});














































import {
  defineComponent,
  PropType,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import FaqAnswer from "@/views/FAQ/FaqAnswer/FaqAnswer.vue";
import ExpansionPanelHeader from "@/components/expansionPanel/ExpansionPanelHeader.vue";
import { Content } from "@/core/dataTypes/types";
import router from "@/router";

export default defineComponent({
  name: "FaqTab",
  components: {
    FaqAnswer,
    ExpansionPanelHeader,
  },
  props: {
    name: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: undefined,
    },
    list: {
      type: Array as PropType<Content[]>,
      default: () => [],
    },
  },
  setup(props: {
    name: string;
    loading: boolean;
    index: number;
    list: Content[];
  }) {
    const actualIndex: Ref<number> = ref(props.index);
    watch(actualIndex, () => {
      router.replace({
        query: {
          tab: props.name,
          slug: props.list?.[actualIndex.value]?.slug,
        },
      });
    });
    return { actualIndex };
  },
});

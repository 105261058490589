














import { CMSTagType } from "@/core/dataTypes/cmsTypes";
import handleNavigationDuplicated from "@/core/utils/errorHandlers/handleNavigationDuplicated";
import router from "@/router";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "Tag",
  props: {
    tag: {
      type: Object as PropType<CMSTagType>,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
  },
  setup(props: { tag: CMSTagType }) {
    const onClick = () => {
      router
        .push({
          name: "NewsListPage",
          query: { page: "1", filters: props.tag.id },
        })
        .catch(handleNavigationDuplicated);
    };
    return { onClick };
  },
});

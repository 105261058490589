import { CMSMapPoint, CMSSalesPointsRequest } from "@/core/dataTypes/cmsTypes";
import { Content, SalesPointDetails } from "@/core/dataTypes/types";
import store from "@/store";

const content = (): Content[] | undefined => store.state.salesPoints?.content;
const contentLoading = (): boolean | undefined =>
  store.state.salesPoints?.contentLoading;
const getContent = (slug: string): Promise<void> =>
  store.dispatch("salesPoints/getContent", slug);

const salesPoints = (): CMSMapPoint[] | undefined =>
  store.state.salesPoints?.salesPoints;
const salesPointsLoading = (): boolean | undefined =>
  store.state.salesPoints?.salesPointsLoading;
const getSalesPoints = (
  tenant: string,
  requestData: CMSSalesPointsRequest
): Promise<void> =>
  store.dispatch("salesPoints/getSalesPoints", { tenant, requestData });
const salesPointDetails = (): SalesPointDetails | undefined =>
  store.state.salesPoints?.salesPointDetails;
const salesPointDetailsLoading = (): boolean | undefined =>
  store.state.salesPoints?.salesPointDetailsLoading;
const getSalesPointDetails = (tenant: string, id: string): Promise<void> =>
  store.dispatch("salesPoints/getSalesPointDetails", { tenant, id });
const resetSalesPointDetails = (): Promise<void> =>
  store.dispatch("salesPoints/resetSalesPointDetails");

const resetModule = (): Promise<void> =>
  store.dispatch("salesPoints/resetModule");

export const salesPointsFacade = {
  content,
  contentLoading,
  getContent,
  salesPoints,
  salesPointsLoading,
  getSalesPoints,
  salesPointDetails,
  salesPointDetailsLoading,
  getSalesPointDetails,
  resetSalesPointDetails,
  resetModule,
};































import { DialogResultType } from "@/core/dataTypes/types";
import {
  defineComponent,
  onUnmounted,
  ref,
  computed,
  Ref,
} from "@vue/composition-api";
import dialogService from "@/core/services/dialog.service";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";

export default defineComponent({
  name: "DialogWrapper",
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: "none",
    },
    maxWidth: {
      type: [String, Number],
      default: "auto",
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    retainFocus: {
      type: Boolean,
      default: true,
    },
    attach: [Boolean, String],
    closeDelay: Number,
    eager: Boolean,
    hideOverlay: Boolean,
    noClickAnimation: Boolean,
    openDelay: Number,
    overlayColor: String,
    overlayOpacity: [Number, String],
    customClass: String,
    component: Object,
    componentProps: [Object, Array, Boolean, String, Number],
    id: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const open = ref<boolean>(true);
    const dialogCount = computed(accessibilityFacade.openedDialogCount);
    const ariaHidden: Ref<string> = computed(() => {
      if (dialogCount.value) {
        const lastDialog =
          dialogService.dialogInstances[
            dialogService.dialogInstances.length - 1
          ];
        return lastDialog.$props.id === props.id ? "false" : "true";
      } else {
        return "true";
      }
    });

    const dialogClose = (): void => {
      ctx.emit("dialogClose");
    };

    const dialogOutsideClose = (): void => {
      if (!props.persistent) {
        dialogClose();
      }
    };

    const dialogResult = (result: DialogResultType): void => {
      ctx.emit("dialogResult", result);
    };

    onUnmounted((): void => {
      ctx.emit("dialogUnmounted");
    });

    return {
      open,
      dialogClose,
      dialogResult,
      dialogOutsideClose,
      ariaHidden,
    };
  },
});

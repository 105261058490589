














































import { computed, defineComponent } from "@vue/composition-api";
import DropDownMenu from "@/components/header/navigation/desktop/dropDownMenu/DropDownMenu.vue";
import { commonFacade } from "@/store/modules/common/common.facade";
import { EMenuTypes } from "@/core/dataTypes/enums";

export default defineComponent({
  name: "DesktopMenu",
  components: {
    DropDownMenu,
  },
  setup() {
    const menu = computed(commonFacade.menu);
    const menuLoading = computed(commonFacade.menuLoading);
    const currentLanguage = computed(commonFacade.currentLanguage);

    return {
      menu,
      menuLoading,
      EMenuTypes,
      currentLanguage,
    };
  },
});

import { CMSMapPoint, CMSPublicCustomerService } from "../dataTypes/cmsTypes";
import { CustomerServiceDetail } from "../dataTypes/types";
import httpService from "../services/http.service";
import { publicHugoCustomerServiceControllerTransformer } from "../transformers/publicHugoCustomerServiceController.transformer";

const getCustomerServices = (
  tenant: string,
  language: string
): Promise<CMSMapPoint[]> =>
  httpService
    .get<CMSMapPoint[]>(
      `/api/hugoCustomerService/public/${tenant}/customer-services`,
      {
        params: { lang: language },
      }
    )
    .then((response) => response.data);

const getCustomerServiceDetail = (
  tenant: string,
  id: string
): Promise<CustomerServiceDetail> => {
  return httpService
    .get<CMSPublicCustomerService>(
      `/api/hugoCustomerService/public/${tenant}/customer-services/${id}`
    )
    .then((response) =>
      publicHugoCustomerServiceControllerTransformer.transformCustomerServiceDetail(
        response.data
      )
    );
};

export const publicHugoCustomerServiceControllerApi = {
  getCustomerServices,
  getCustomerServiceDetail,
};

export enum CategoryNames {
  COOKIE = "Cookie",
  CONTENT = "Kapcsolat",
  NEWS = "Hírek",
  HEADER = "Header",
  FOOTER = "Footer",
  EMATRICA_FAQ = "E-matrica Gyakran Ismételt Kérdések",
  HUGO_FAQ = "E-útdíj Gyakran Ismételt Kérdések",
  OTHER_LANGUAGE = "Egyéb nyelvek",
}

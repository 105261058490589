import { Content } from "@/core/dataTypes/types";
import store from "@/store";

const newsContent = (): Content | undefined => store.state.news?.newsContent;
const newsContentLoading = (): boolean | undefined =>
  store.state.news?.newsContentLoading;
const getNewsContent = (slug: string): Promise<void> =>
  store.dispatch("news/getNewsContent", slug);
const newsContentReset = (): Promise<void> =>
  store.dispatch("news/newsContentReset");

const newsContentList = (): Content[] | undefined =>
  store.state.news?.newsContentList;
const newsContentListLoading = (): boolean | undefined =>
  store.state.news?.newsContentListLoading;
const newsContentListPageCount = (): number | undefined =>
  store.state.news?.newsContentListPageCount;
const getNewsContentList = (page: number, tagIds?: string[]): Promise<void> =>
  store.dispatch("news/getNewsContentList", { page, tagIds });

const newsListPageContent = (): Content | undefined =>
  store.state.news?.newsListPageContent;
const newsListPageContentLoading = (): boolean | undefined =>
  store.state.news?.newsListPageContentLoading;
const getNewsListPageContent = (slug: string): Promise<void> =>
  store.dispatch("news/getNewsListPageContent", slug);

export const newsFacade = {
  newsContent,
  newsContentLoading,
  getNewsContent,
  newsContentReset,
  newsContentList,
  newsContentListLoading,
  newsContentListPageCount,
  getNewsContentList,
  newsListPageContent,
  newsListPageContentLoading,
  getNewsListPageContent,
};

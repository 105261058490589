import format from "date-fns/format";

export const dateFormat = (date: string | Date): string =>
  format(typeof date === "string" ? new Date(date) : date, "yyyy. MM. dd.");

export const dateAndTimeFormat = (date: string | Date): string =>
  format(
    typeof date === "string" ? new Date(date) : date,
    "yyyy. MM. dd. HH:mm"
  );

import { publicControllerApi } from "@/core/api/publicController.api";
import {
  Content,
  ContentSearchFilter,
  ContentSearchSort,
  ContentsRequestModel,
  ContentsResponse,
  FilterModel,
  SortRequestModel,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";

export interface SearchState {
  searchContentList: Content[];
  searchContentListLoading: boolean;
  searchContentListPageCount: number;
  totalNumberOfItems: number;
}

export const initialSearchState: SearchState = {
  searchContentList: [] as Content[],
  searchContentListLoading: false,
  searchContentListPageCount: 1,
  totalNumberOfItems: 0,
};

const PAGE_SIZE = 8;

export const searchModule: Module<SearchState, AppState> = {
  namespaced: true,
  state: initialSearchState,
  mutations: {
    searchContentListRequest: (state) => {
      state.searchContentListLoading = true;
    },
    searchContentListSuccess: (state, payload: ContentsResponse) => {
      state.searchContentList = payload.data;
      state.totalNumberOfItems = payload.totalNumberOfItems;
      state.searchContentListLoading = false;
      state.searchContentListPageCount = Math.ceil(
        payload.totalNumberOfItems / PAGE_SIZE
      );
    },
    searchContentListFail: (state) => {
      state.searchContentListLoading = false;
    },
  },
  actions: {
    getSearchContentListByKeyword: (
      { commit, rootState },
      { page, keyword }: { page: number; keyword?: string }
    ) => {
      commit("searchContentListRequest");
      const payload: ContentsRequestModel<
        ContentSearchSort,
        ContentSearchFilter
      > = {
        take: PAGE_SIZE,
        skip: PAGE_SIZE * (page - 1),
        sort: {
          visibilityFrom: "DESC",
        },
        filter: {
          languageCode: rootState.common?.currentLanguage as string,
          keyword: keyword ? keyword : "",
        },
        contentBodyReturn: false,
      };
      return publicControllerApi
        .contentSearch(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("searchContentListSuccess", response);
        })
        .catch((error) => {
          commit("searchContentListFail");
          throw Error(error);
        });
    },
    getSearchContentList: (
      { commit, rootState },
      { page, tagIds }: { page: number; tagIds?: string[] }
    ) => {
      commit("searchContentListRequest");
      const payload: ContentsRequestModel<SortRequestModel, FilterModel> = {
        take: PAGE_SIZE,
        skip: PAGE_SIZE * (page - 1),
        sort: {
          visibilityFrom: "DESC",
        },
        filter: {
          languageCode: rootState.common?.currentLanguage as string,
          tags: tagIds?.length ? tagIds : undefined,
        },
        contentBodyReturn: false,
      };
      return publicControllerApi
        .getContents(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("searchContentListSuccess", response);
        })
        .catch((error) => {
          commit("searchContentListFail");
          throw Error(error);
        });
    },
  },
};

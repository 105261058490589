



























































import { defineComponent, ref, SetupContext } from "@vue/composition-api";
import TextInput from "@/components/forms/text/TextInput.vue";
import AutocompleteInput from "@/components/autocomplete/AutocompleteInput.vue";
import { validationRegExp } from "@/core/utils/validation/validationRegExp";

export default defineComponent({
  name: "VehicleAddForm",
  props: {
    value: Object,
    flags: Array,
    plateNumberValidationLoading: Boolean,
  },
  components: {
    AutocompleteInput,
    TextInput,
  },
  setup(props, { emit }: SetupContext) {
    const innerValue = ref(props.value);

    const onPlateNumberBlur = () => {
      const iv = innerValue.value as {
        plateNumber: string;
        countryCode: string;
      };
      if (iv.plateNumber && iv.countryCode) {
        emit("plateNumberBlur");
      }
    };
    return {
      innerValue,
      validationRegExp,
      onPlateNumberBlur,
    };
  },
});





















































import Tag from "@/components/tag/Tag.vue";
import { Content } from "@/core/dataTypes/types";
import { calculatedContentUrl } from "@/core/utils/components/calculatedContentUrl.util";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ContentCard",
  components: {
    Tag,
  },
  props: {
    content: {
      type: Object as PropType<Content>,
      required: true,
    },
  },
  setup(props: { content: Content }) {
    return {
      contentUrl: calculatedContentUrl({
        menuPath: props.content?.menuPath,
        contentCategoryId: props.content?.contentCategoryId,
        slug: props.content?.slug,
      }),
    };
  },
});

import axios, { AxiosInstance } from "axios";
import { uuid } from "@/core/utils/uuid.util";
import { Subject, Observable } from "rxjs";
import { commonFacade } from "@/store/modules/common/common.facade";

const http: AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-LANGUAGE": commonFacade.currentLanguage()?.toUpperCase() as string,
    // "Access-Control-Allow-Credentials": "false",
  },
});
const subject = new Subject<void>();

export const getCountryCodeError = (): Observable<void> =>
  subject.asObservable();

/* eslint-disable @typescript-eslint/no-explicit-any */
export const plateNumberValid = (
  requestData: any
): Promise<{ validationResult: string }> => {
  const req = {
    ...requestData,
    context: {
      requestId: uuid(),
    },
  };
  return http
    .post<never>("/PublicService/public/validatePlateNumber", req)
    .then((response) => response.data);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getCountryCodes = (): Promise<any> =>
  http
    .get<never>(`/PublicService/public/countryCodes/mostCommon`)
    .then((response: any) => {
      const countryData =
        response.data.mostCommonCountryCodeList?.mostCommonCountryCode;
      return countryData?.map((country: any) => {
        return {
          ...country,
          fullName: `${country.countryCode} - ${country.name}`,
        };
      });
    });

export const postHighwaySticker = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestData: any
): Promise<any> => {
  const req = {
    ...requestData,
    context: {
      requestId: uuid(),
    },
  };
  return (
    http
      .post<never>(
        `/HighwayPurchaseService/highwayPurchase/highwaySticker/public/query`,
        req
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((response: any) => {
        return response.data;
      })
  );
};

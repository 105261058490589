

























import Tag from "@/components/tag/Tag.vue";
import { Content } from "@/core/dataTypes/types";
import { calculatedContentUrl } from "@/core/utils/components/calculatedContentUrl.util";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "ContentMiniCard",
  components: {
    Tag,
  },
  props: {
    content: {
      type: Object as PropType<Content>,
      required: true,
    },
    subContent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: { content: Content; subContent: boolean }) {
    const contentUrl = calculatedContentUrl({
      menuPath: props.content?.menuPath,
      contentCategoryId: props.content?.contentCategoryId,
      slug: props?.content.slug,
      subContent: props?.subContent,
      id: props?.content.id,
    });
    return {
      contentUrl,
    };
  },
});

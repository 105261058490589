import { CMSTagType } from "@/core/dataTypes/cmsTypes";
import store from "@/store";

const tags = (): CMSTagType[] | undefined => store.state.tag?.tags;
const tagsLoading = (): boolean | undefined => store.state.tag?.tagsLoading;
const getTags = (): Promise<void> => store.dispatch("tag/getTags");

export const tagFacade = {
  tags,
  tagsLoading,
  getTags,
};

import {
  Content,
  ContentsResponse,
  KeyValue,
  Language,
  MediaCollection,
  Menu,
  MenuItem,
  Tenant,
  TitleLocalization,
} from "../dataTypes/types";
import {
  CMSContent,
  CMSContentsResponse,
  CMSKeyValue,
  CMSLanguage,
  CMSLocalization,
  CMSMediaCollectionItem,
  CMSMediaCollectionResponse,
  CMSMenuCategory,
  CMSPublicMenuItem,
  CMSPublicTenant,
} from "../dataTypes/cmsTypes";
import { dateAndTimeFormat, dateFormat } from "../utils/date/dateFormats.util";
import { commonTransformer } from "./common.transformer";

const transformMenu = (data: CMSPublicMenuItem[]): Menu => {
  const transformMenuItem = (current: CMSPublicMenuItem): MenuItem => ({
    id: current.id,
    caption: current.caption,
    path: current.path,
    ...(current.contents?.length && { contents: current.contents }),
    ...(current.url && { url: current.url }),
    children:
      current?.children?.map((menu: CMSPublicMenuItem) => {
        return transformMenuItem(menu);
      }) ?? [],
    menuType: current.menuType,
  });

  return data.reduce((acc: Menu, curr) => {
    let accumulator = acc;
    curr.menuCategory.forEach((category: CMSMenuCategory) => {
      const lowerCaseName = category.name.toLowerCase();
      accumulator = {
        ...accumulator,
        [lowerCaseName]: accumulator[lowerCaseName]?.length
          ? [...accumulator[lowerCaseName], transformMenuItem(curr)]
          : [transformMenuItem(curr)],
      };
    });
    return accumulator;
  }, {});
};

const transformContent = (data: CMSContent): Content => ({
  id: data.id,
  title: data.title,
  ...(data.subtitle && {
    subtitle: commonTransformer.transformHTMLContent(data.subtitle),
  }),
  ...(data.lead && { lead: data.lead }),
  ...(data.contentBody && {
    contentBody: commonTransformer.transformHTMLContent(data.contentBody),
  }),
  slug: data.slug,
  ...(data.cover && {
    cover: commonTransformer.transformMediaResponse(data.cover),
  }),
  tag: data.tag.length ? data.tag : [],
  ...(data.seoLead && { seoLead: data.seoLead }),
  ...(data.seoTitle && { seoTitle: data.seoTitle }),
  ...(data.seoCover && {
    seoCover: commonTransformer.transformMediaResponse(data.seoCover),
  }),
  ...(data.customInsDate &&
    data.customInsDatetimeVisible && {
      customInsDate: data.customInsTimeVisible
        ? dateAndTimeFormat(data.customInsDate)
        : dateFormat(data.customInsDate),
    }),
  ...(data.customModDate &&
    data.modDateVisible && {
      customModDate: data.customModTimeVisible
        ? dateAndTimeFormat(data.customModDate)
        : dateFormat(data.customModDate),
    }),
  socialMediaIconsVisible: data.socialMediaIconsVisible ?? false,
  contentCategoryId: data.contentCategory || data.contentCategoryId,
  menuPath: data.menuPath,
  contentCategoryName: data.contentCategoryName || data.categoryName,
});

const transformContentsResponse = (
  response: CMSContentsResponse
): ContentsResponse => ({
  totalNumberOfItems: response.totalNumberOfItems,
  data: sortContentsByCategories(
    response.data.map((item: CMSContent) => transformContent(item))
  ),
});

const sortContentsByCategories = (contents: Content[]): Content[] => {
  const sortedContents: Content[] = [];
  getSortedCategories(contents).forEach((category: string) => {
    contents.forEach((content: Content) => {
      if (content.contentCategoryId === category) {
        sortedContents.push(content);
      }
    });
  });
  return sortedContents;
};

const getSortedCategories = (contents: Content[]): string[] => {
  const categories: string[] = [];
  contents.forEach((content: Content) => {
    if (!categories.includes(content.contentCategoryId as string)) {
      categories.push(content.contentCategoryId as string);
    }
  });
  return categories.sort();
};

const transformLanguageList = (list: CMSLanguage[]): Language[] =>
  list.map(
    (item: CMSLanguage): Language => ({
      code: item.code,
      englishName: item.englishName,
    })
  );

const transformLocalization = (data: CMSLocalization[]): TitleLocalization =>
  data.reduce((acc: TitleLocalization, currentValue: CMSLocalization) => {
    return {
      ...acc,
      [currentValue.languageCode as string]: currentValue.websiteTitle,
    };
  }, {});

const transformTenantResponse = (data: CMSPublicTenant): Tenant => ({
  localization: data.localization,
  description: data.description,
  languages: transformLanguageList(data.languages),
  title: transformLocalization(data.localization),
  ...(data.defaultCover && {
    defaultCover: commonTransformer.transformMediaResponse(data.defaultCover),
  }),
  ...(data.icon && {
    icon: commonTransformer.transformMediaResponse(data.icon),
  }),
  ...(data.logo && {
    logo: commonTransformer.transformMediaResponse(data.logo),
  }),
});

const transformMediaCollection = (
  data: CMSMediaCollectionResponse
): MediaCollection => ({
  id: data.id,
  name: data.name,
  items: data.items.map((item: CMSMediaCollectionItem) => ({
    id: item.id,
    name: item.name,
    targetUrl: item.targetUrl,
    lead: item.lead,
    media: commonTransformer.transformMediaResponse(item.media),
  })),
});

const transformKeyValue = (data: CMSKeyValue[]): KeyValue =>
  data.reduce((acc: KeyValue, curr: CMSKeyValue) => {
    return {
      ...acc,
      [curr.key]: curr,
    };
  }, {});

export const publicControllerTransformer = {
  transformContent,
  transformMenu,
  transformContentsResponse,
  transformTenantResponse,
  transformKeyValue,
  transformMediaCollection,
};

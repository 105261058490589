



















































































import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from "@vue/composition-api";
import router, { useRoute } from "@/router";
import { searchFacade } from "@/store/modules/search/search.facade";
import { tagFacade } from "@/store/modules/tag/tag.facade";
import handleNavigationDuplicated from "@/core/utils/errorHandlers/handleNavigationDuplicated";
import { CMSTagType } from "@/core/dataTypes/cmsTypes";
import HorizontalContentCard from "@/components/horizontalContentCard/HorizontalContentCard.vue";

export default defineComponent({
  name: "SearchListPage",
  components: {
    HorizontalContentCard,
  },
  setup() {
    const page: Ref<number> = ref(1);
    const searchContentList = computed(searchFacade.searchContentList);
    const searchContentListPageCount = computed(
      searchFacade.searchContentListPageCount
    );
    const searchContentListLoading = computed(
      searchFacade.searchContentListLoading
    );
    const totalNumberOfItems = computed(searchFacade.totalNumberOfItems);
    const tags: Ref<CMSTagType[] | undefined> = computed(tagFacade.tags);
    const filters = ref<string[]>([]);
    const keyword = ref<string>("");
    const route = useRoute();
    const path = computed(() => {
      const filtersString = filters.value.join(",");
      return `${route.value.path}?page=${page.value}&filters=${filtersString}&keyword=${keyword.value}`;
    });
    const isKeywordSearch = ref<boolean>(true);

    if (Object.keys(route.value.query).length) {
      page.value = parseInt((route.value.query.page as string) ?? "1");
      filters.value =
        (route.value.query.filters as string)
          .split(",")
          .filter((tagId) => tagId) ?? [];
      keyword.value = (route.value.query.keyword as string) ?? "";
    } else {
      router.replace(path.value);
    }

    searchFacade.getSearchContentListByKeyword(page.value, keyword.value);
    tagFacade.getTags();

    watch(filters, (newFilters) => {
      if (newFilters.length || keyword.value === "") {
        isKeywordSearch.value = false;
        keyword.value = "";
        page.value = 1;
        router.push(path.value).catch(handleNavigationDuplicated);
      }
    });

    watch(page, (newPage) => {
      if (+route.value.query?.page !== newPage) {
        router.push(path.value).catch(handleNavigationDuplicated);
      }
    });

    watch(
      () => route.value.query,
      (newQuery, prewQuery) => {
        if (newQuery.keyword !== prewQuery.keyword && newQuery.keyword) {
          page.value = 1;
          filters.value = [];
          keyword.value = newQuery.keyword as string;
          isKeywordSearch.value = true;
        }
        if (isKeywordSearch.value) {
          searchFacade.getSearchContentListByKeyword(page.value, keyword.value);
        } else {
          searchFacade.getSearchContentList(page.value, filters.value);
        }
      }
    );

    return {
      searchContentList,
      totalNumberOfItems,
      searchContentListPageCount,
      searchContentListLoading,
      page,
      tags,
      filters,
      keyword,
    };
  },
});

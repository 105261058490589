






















































import { CustomerServiceDetail } from "@/core/dataTypes/types";
import { computed, defineComponent, PropType } from "@vue/composition-api";
import getISODay from "date-fns/getISODay";
import { customerServiceOpeningHourLabels } from "@/core/utils/components/customerServiceOpeningHours.util";
import dialogService from "@/core/services/dialog.service";
import CustomerServiceInformationDialog from "../customerServiceInformationDialog/CustomerServiceInformationDialog.vue";

export default defineComponent({
  name: "CustomerServiceInformationPreview",
  props: {
    details: {
      type: Object as PropType<CustomerServiceDetail>,
      required: true,
    },
  },
  setup(props) {
    const actualDay = getISODay(new Date()) - 1;
    const openingHourLabels = computed(() =>
      customerServiceOpeningHourLabels(props.details.contactOpeningHours)
    );

    const openDetailsDialog = () => {
      dialogService.open({
        fullscreen: true,
        hideOverlay: true,
        component: CustomerServiceInformationDialog,
        componentProps: {
          details: props.details,
        },
      });
    };

    return {
      openDetailsDialog,
      actualDay,
      openingHourLabels,
    };
  },
});

import {
  ContentCategories,
  KeyValue,
  Menu,
  Content,
  Tenant,
} from "@/core/dataTypes/types";
import store from "@/store";

const keyValuePairs = (): KeyValue | undefined =>
  store.state.common?.keyValuePairs;
const keyValuePairsLoading = (): boolean | undefined =>
  store.state.common?.keyValuePairsLoading;
const getKeyValuePairs = (): Promise<void> =>
  store.dispatch("common/getKeyValuePairs");

const tenant = (): Tenant | undefined => store.state.common?.tenant;
const tenantLoading = (): boolean | undefined =>
  store.state.common?.tenantLoading;
const getTenant = (): Promise<void> => store.dispatch("common/getTenant");

const languageCodes = (): string[] => store.getters["common/languageCodes"];

const menu = (): Menu | undefined => store.state.common?.menu;
const menuLoading = (): boolean | undefined => store.state.common?.menuLoading;
const getMenu = (): Promise<void> => store.dispatch("common/getMenu");
const resetMenu = (): Promise<void> => store.dispatch("common/resetMenu");

const contentCategories = (): ContentCategories[] | undefined =>
  store.state.common?.contentCategories;
const contentCategoriesLoading = (): boolean | undefined =>
  store.state.common?.contentCategoriesLoading;
const getContentCategories = (): Promise<void> =>
  store.dispatch("common/getContentCategories");
const cookieId = (): string | undefined => store.getters["common/getCookieId"];

const cookieContent = (): Content | undefined =>
  store.state.common?.cookieContent;
const cookieContentLoading = (): boolean | undefined =>
  store.state.common?.cookieContentLoading;
const getCookieContent = (): Promise<void> =>
  store.dispatch("common/getCookieContent");
const resetCookieContent = (): Promise<void> =>
  store.dispatch("common/resetCookieContent");

const currentLanguage = (): string | undefined =>
  store.state.common?.currentLanguage;
const setCurrentLanguage = (languageCode: string): Promise<void> =>
  store.dispatch("common/setCurrentLanguage", languageCode);

const contactContent = (): Content | undefined =>
  store.state.common?.contactContent;
const contactContentLoading = (): boolean | undefined =>
  store.state.common?.contactContentLoading;
const getContactContent = (): Promise<void> =>
  store.dispatch("common/getContactContent");
const getContactCategoryId = (): string | undefined =>
  store.getters["common/getContactCategoryId"];
const resetContactContent = (): Promise<void> =>
  store.dispatch("common/resetContactContent");

export const commonFacade = {
  keyValuePairs,
  keyValuePairsLoading,
  getKeyValuePairs,
  tenant,
  tenantLoading,
  getTenant,
  menu,
  menuLoading,
  getMenu,
  resetMenu,
  contentCategories,
  contentCategoriesLoading,
  getContentCategories,
  cookieId,
  cookieContent,
  cookieContentLoading,
  getCookieContent,
  resetCookieContent,
  currentLanguage,
  setCurrentLanguage,
  languageCodes,
  contactContent,
  contactContentLoading,
  getContactContent,
  getContactCategoryId,
  resetContactContent,
};

import i18n from "@/plugins/i18n";
import { CMSHoursType, CMSPublicFile } from "../dataTypes/cmsTypes";
import { HTMLContent, MediaResponse, OpeningHours } from "../dataTypes/types";
import getDay from "date-fns/getDay";
import { isBetween } from "../utils/date/isBetween.util";
import { EhtmlContentTypes } from "../dataTypes/enums";
import { decode } from "html-entities";

const transformOpeningHours = (data: CMSHoursType): OpeningHours => ({
  ...(data && { hours: data }),
  ...(data && {
    hoursArray: transformDetailHours(data),
  }),
  isOpen: transformIsOpen(data),
});

const transformDetailHours = (data: CMSHoursType): string[] => {
  return Object.keys(data).map((day) => {
    const dataValue = data[day as keyof CMSHoursType];
    const item = dataValue === null ? "" : dataValue;
    const translatedDayName = i18n.t(`OPENING_HOURS.DAYS.${day.toUpperCase()}`);
    return `${translatedDayName}, ${item ?? i18n.t("OPENING_HOURS.CLOSE")}`;
  });
};

const transformIsOpen = (data: CMSHoursType): boolean | string => {
  const dayIndex = getDay(new Date());
  switch (dayIndex) {
    case 0:
      return isOpen(data.su);
    case 1:
      return isOpen(data.mo);
    case 2:
      return isOpen(data.tu);
    case 3:
      return isOpen(data.we);
    case 4:
      return isOpen(data.th);
    case 5:
      return isOpen(data.fr);
    case 6:
      return isOpen(data.sa);
    default:
      return false;
  }
};

const isOpen = (openCloseInfo: string): boolean | string => {
  // console.log('isOpen vizsgálat: ', openCloseInfo);
  if (openCloseInfo) {
    try {
      const splitOpeningHour = openCloseInfo.split(" ", 3);
      const splitOpenTime = splitOpeningHour[0].split(":", 2);
      const splitCloseTime = splitOpeningHour[2].split(":", 2);
      const openDate = new Date();
      openDate.setHours(+splitOpenTime[0]);
      openDate.setMinutes(+splitOpenTime[1]);
      openDate.setSeconds(0);

      const closeDate = new Date();
      closeDate.setHours(+splitCloseTime[0]);
      closeDate.setMinutes(+splitCloseTime[1]);
      closeDate.setSeconds(59);
      // console.log('-- belefutott --');
      if (!isNaN(openDate as never) && !isNaN(closeDate as never)) {
        return isBetween(new Date(), openDate, closeDate);
      } else {
        return "N/A";
      }
    } catch (e) {
      return "N/A";
    }
  } else {
    return "N/A";
    // return false;
  }
};
/*const isOpen = (openCloseInfo: string): boolean=> {
  if (openCloseInfo) {
    const splitOpeningHour = openCloseInfo.split(" ", 3);
    const splitOpenTime = splitOpeningHour[0].split(":", 2);
    const splitCloseTime = splitOpeningHour[2].split(":", 2);

    const openDate = new Date();
    openDate.setHours(+splitOpenTime[0]);
    openDate.setMinutes(+splitOpenTime[1]);
    openDate.setSeconds(0);

    const closeDate = new Date();
    closeDate.setHours(+splitCloseTime[0]);
    closeDate.setMinutes(+splitCloseTime[1]);
    closeDate.setSeconds(59);

    return isBetween(new Date(), openDate, closeDate);
  } else {
    return false;
  }
};*/

const transformMediaResponse = (data: CMSPublicFile): MediaResponse => {
  const { alternativeText, caption, thumbnails, url, ext, mime } = data;

  return {
    altText: alternativeText ?? "",
    caption,
    url,
    ext,
    mime,
    thumbnails,
  };
};

const transformHTMLContentChilds = (item: Element): HTMLContent[] => {
  const extraContentTypes: EhtmlContentTypes[] = [
    EhtmlContentTypes.SLIDESHOW,
    EhtmlContentTypes.GALLERY,
    EhtmlContentTypes.DOCUMENT_LIST,
  ];
  return Array.from(item.children).reduce(
    (
      previousValue: HTMLContent[],
      currentValue: Element,
      index: number
    ): HTMLContent[] => {
      let acc = previousValue;
      const concatenateHtml = (
        acc: HTMLContent[],
        currentValue: Element
      ): HTMLContent[] => {
        acc[acc.length - 1].data =
          acc[acc.length - 1].data + currentValue.outerHTML;

        return acc;
      };

      if (currentValue.className !== "cms-media-collection") {
        acc =
          index === 0
            ? [
                {
                  type: EhtmlContentTypes.SIMPLE_HTML,
                  data: currentValue.outerHTML,
                },
              ]
            : extraContentTypes.includes(acc[acc.length - 1].type)
            ? [
                ...acc,
                {
                  type: EhtmlContentTypes.SIMPLE_HTML,
                  data: currentValue.outerHTML,
                },
              ]
            : concatenateHtml(acc, currentValue);
      } else {
        const id = currentValue.getAttribute("data-id") as string;
        const name = currentValue.getAttribute("data-name") as string;
        const type = currentValue.getAttribute("data-type");
        const itemCount = Number(currentValue.getAttribute("data-item-count"));
        let contentType: EhtmlContentTypes;

        switch (type) {
          case EhtmlContentTypes.SLIDESHOW:
            contentType = EhtmlContentTypes.SLIDESHOW;
            break;
          case EhtmlContentTypes.GALLERY:
            contentType = EhtmlContentTypes.GALLERY;
            break;
          default:
            contentType = EhtmlContentTypes.DOCUMENT_LIST;
            break;
        }

        acc = [
          ...acc,
          {
            type: contentType,
            data: {
              id,
              name,
              itemCount,
            },
          },
        ];
      }

      return acc;
    },
    []
  );
};

const transformHTMLContent = (content: string): HTMLContent[][] => {
  const parser = new DOMParser();
  const parsedHTML: Document = parser.parseFromString(
    decode(content),
    "text/html"
  );
  const documentBody = parsedHTML.body;

  return Array.from(documentBody.children).map((item) =>
    transformHTMLContentChilds(item)
  );
};

export const commonTransformer = {
  transformOpeningHours,
  transformMediaResponse,
  transformHTMLContent,
};

export const validationRegExp: Record<string, RegExp> = {
  defaultZIPCode: /^([A-Z0-9 /-]{2,15})|()$/,
  defaultHungarienTaxNumber: /^[0-9-]{6,15}$/,
  defaultEUVatNumber: /^[A-Z]{2}[0-9A-Z-]{0,13}$/,
  defaultEUCountryVatNumber: /^[0-9]{2}[0-9A-Z-]{0,13}$/,
  defaultNotEUCountryVatNumber: /^[0-9A-Z-]{1,15}$/,
  hungarianPlateNumberPattern:
    /^((CD)[0-9]{6}|[A-Z]{1}[0-9]{5}|[A-Z]{2}[0-9]{4}|[A-Z]{3}[0-9]{3}|[A-Z]{4}[0-9]{2}|[A-Z]{5}[0-9]{1})$/,
  foreignPlateNumberPattern: /^[A-Z0-9ŐŰ]{3,15}$/,
  diplomatPlateNumberPattern: /^(CD)([0-9]{6})$/,
  invoiceNumberPattern: /^[0-9]{1,8}$/,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_vm._t("default"),_c('l-map',{ref:"map",staticClass:"position-absolute",staticStyle:{"opacity":"0.9"},attrs:{"zoom":_vm.zoom,"center":_vm.center,"options":{
      zoomControl: false,
      attributionControl: true,
      gestureHandling: true,
      gestureHandlingText: {
        touch: _vm.$t('MAP.TOUCH'),
        scroll: _vm.$t('MAP.SCROLL'),
        scrollMac: _vm.$t('MAP.SCROLL_MAC', { command: '\u2318' }),
      },
    }},on:{"ready":function($event){return _vm.mapIsReady()}}},[_c('l-tile-layer',{attrs:{"url":_vm.url}}),(_vm.$root.$vuetify.breakpoint.smAndUp)?_c('l-control-zoom',{attrs:{"position":"bottomright"}}):_vm._e(),(_vm.points)?[_vm._l((_vm.points),function(item){return [(!_vm.selectedPoint || _vm.selectedPoint === item.id)?[_c('l-marker',{key:item.id,attrs:{"lat-lng":[item.latitude, item.longitude],"options":{ keyboard: false }},on:{"click":function($event){return _vm.clickOnMarker(item)}}},[_c('l-icon',[_c('img',{staticClass:"icon-style",attrs:{"src":require('@/assets/logokor.svg')}})]),_c('l-tooltip',[_vm._v(" "+_vm._s(item.name || item.city)+" ")])],1)]:_vm._e()]})]:_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
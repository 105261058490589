import { DialogResultType } from "@/core/dataTypes/types";
import { SetupContext } from "@vue/composition-api";

export const dialogCloseEmitter = (ctx: SetupContext): void => {
  ctx.emit("close");
};

export const dialogResultEmitter = (
  ctx: SetupContext,
  result: DialogResultType
): void => {
  ctx.emit("result", result);
};














































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import CustomerServiceInformation from "@/views/customerService/components/customerServiceInformation/CustomerServiceInformation.vue";
import CustomerServiceInformationPreview from "@/views/customerService/components/customerServiceInformationPreview/CustomerServiceInformationPreview.vue";
import { customerServicesFacade } from "@/store/modules/customer-services/customer-services.facade";
import router, { useRoute } from "@/router";
import { FitBoundsOptions } from "leaflet";
import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";
import MapContainer from "@/components/map/MapContainer/MapContainer.vue";
import {
  mapPointSelectedOption,
  mapInitialOption,
} from "@/core/utils/components/mapOption.util";

export default defineComponent({
  name: "CustomerService",
  components: {
    AutocompleteInput,
    CustomerServiceInformation,
    CustomerServiceInformationPreview,
    MapContainer,
  },
  setup(props, { root }) {
    const route = useRoute();
    const content = computed(customerServicesFacade.customerServicesContent);
    const customerServices = computed(customerServicesFacade.customerServices);
    const selectedCustomerService = ref<string>();
    const customerServiceDetail = computed(
      customerServicesFacade.customerServiceDetail
    );

    const mapActivePointSelectedOption = computed<FitBoundsOptions>(() =>
      mapPointSelectedOption(root.$vuetify.breakpoint)
    );

    const selectMarkerOnMap = (selectedItem: CMSMapPoint) => {
      selectedCustomerService.value = selectedItem.id;
    };

    watch(selectedCustomerService, (newValue) => {
      if (newValue) {
        customerServicesFacade.resetCustomerServiceDetail();
        customerServicesFacade.getCustomerServiceDetail(newValue);
      }
    });

    customerServicesFacade
      .getCustomerServicesContent(route.value.slug as string)
      .catch(() => {
        router.push({ name: "Home" });
      });

    customerServicesFacade.getCustomerServices();

    return {
      content,
      customerServices,
      selectMarkerOnMap,
      selectedCustomerService,
      customerServiceDetail,
      mapInitialOption,
      mapActivePointSelectedOption,
    };
  },
  metaInfo() {
    const title: string =
      (this.content?.seoTitle as string) ?? (this.content?.title as string);
    const description = this.content?.seoLead as string;
    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
    };
  },
});














































































































import { defineComponent, computed } from "@vue/composition-api";
import LoginCard from "@/components/loginCard/LoginCard.vue";
import ContentCard from "@/components/contentCard/ContentCard.vue";
import ContentMiniCard from "@/components/contentMiniCard/ContentMiniCard.vue";
import { homeFacade } from "@/store/modules/home/home.facade";
import SlideShowComponent from "@/components/slideshow/Slideshow.vue";
import { commonFacade } from "@/store/modules/common/common.facade";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import {
  baseLanguages,
  ematricaLink,
  ematricaLoginLinks,
  ematricaBuyLinks,
} from "@/core/utils/languageHandler.util";

export default defineComponent({
  name: "Home",
  components: {
    LoginCard,
    ContentCard,
    ContentMiniCard,
    SlideShowComponent,
    HTMLContentRenderer,
  },
  setup() {
    const ematricaLoginLink = ematricaLink(ematricaLoginLinks);
    const ematricaBuyLink = ematricaLink(ematricaBuyLinks);
    const hugoUrl = process.env.VUE_APP_HUGO_URL;

    const highlightedNews = computed(homeFacade.highlightedNews);
    const simpleNews = computed(homeFacade.simpleNews);
    const currentLang = computed(commonFacade.currentLanguage);
    const keyValuePairs = computed(commonFacade.keyValuePairs);
    const slideshowID = computed<string | undefined>(() => {
      const lang = currentLang.value;
      const transformLang = lang
        ? lang.charAt(0).toUpperCase() + lang.slice(1)
        : undefined;
      return keyValuePairs?.value &&
        keyValuePairs?.value[`Slideshow${transformLang}`]?.value
        ? keyValuePairs.value[`Slideshow${transformLang}`].value
        : undefined;
    });
    const otherLanguageContent = computed(homeFacade.otherLanguageContent);
    const otherLanguageContentLoading = computed(
      homeFacade.otherLanguageContentLoading
    );

    if (currentLang.value && baseLanguages.includes(currentLang.value)) {
      homeFacade.getNewsList();
    } else {
      homeFacade.getOtherLanguageContent();
    }

    return {
      ematricaLoginLink,
      ematricaBuyLink,
      highlightedNews,
      simpleNews,
      slideshowID,
      otherLanguageContent,
      baseLanguages,
      otherLanguageContentLoading,
      currentLang,
      hugoUrl,
    };
  },
});

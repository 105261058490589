








import { AlertDialogPropsType } from "@/core/dataTypes/types";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "AlertDialog",
  props: {
    dialogProps: {
      type: Object as PropType<AlertDialogPropsType>,
      required: true,
    },
  },

  setup(props, ctx) {
    const titleValue = props.dialogProps?.title;
    const contentValue = props.dialogProps?.content;

    const closeAlert = (): void => {
      dialogCloseEmitter(ctx);
    };

    return {
      titleValue,
      contentValue,
      closeAlert,
    };
  },
});

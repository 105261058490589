import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import {
  commonModule,
  CommonState,
  initialCommonState,
} from "./modules/common/common.module";
import cloneDeep from "lodash/fp/cloneDeep";
import {
  customerServicesModule,
  CustomerServicesState,
  initialCustomerServices,
} from "./modules/customer-services/customer-services.module";
import {
  newsModule,
  NewsState,
  initialNewsState,
} from "./modules/news/news.module";
import {
  searchModule,
  SearchState,
  initialSearchState,
} from "./modules/search/search.module";
import {
  customModule,
  CustomState,
  initialCustomState,
} from "./modules/custom/custom.module";
import { faqModule, FAQState, initialFAQState } from "./modules/faq/faq.module";
import {
  homeModule,
  HomeState,
  initialHomeState,
} from "./modules/home/home.module";
import { initialTagState, tagModule, TagState } from "./modules/tag/tag.module";
import {
  initialSalesPointsState,
  salesPointsModule,
  SalesPointsState,
} from "./modules/salesPoints/salesPoints.module";
import {
  accessibilityModule,
  AccessibilityState,
  initialAccessibilityState,
} from "@/store/modules/accessibility/accessibility.module";

Vue.use(Vuex);
export interface AppState {
  common?: CommonState;
  customerServices?: CustomerServicesState;
  news?: NewsState;
  search?: SearchState;
  custom?: CustomState;
  faq?: FAQState;
  home?: HomeState;
  tag?: TagState;
  salesPoints?: SalesPointsState;
  accessibility?: AccessibilityState;
}

const initialState: AppState = {
  common: initialCommonState,
  customerServices: initialCustomerServices,
  news: initialNewsState,
  search: initialSearchState,
  custom: initialCustomState,
  faq: initialFAQState,
  home: initialHomeState,
  tag: initialTagState,
  salesPoints: initialSalesPointsState,
  accessibility: initialAccessibilityState,
};

const vuexPersist = new VuexPersistence<AppState>({
  strictMode: process.env.NODE_ENV === "production" ? false : true,
  storage: localStorage,
  key: "nusz",
});

export default new Vuex.Store<AppState>({
  strict: process.env.NODE_ENV === "production" ? false : true,
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    resetState: (state) => {
      Object.assign(state, cloneDeep(initialState));
    },
  },
  actions: {
    resetState: ({ commit }) => {
      commit("resetState");
    },
  },
  modules: {
    common: commonModule,
    customerServices: customerServicesModule,
    news: newsModule,
    search: searchModule,
    custom: customModule,
    faq: faqModule,
    home: homeModule,
    tag: tagModule,
    salesPoints: salesPointsModule,
    accessibility: accessibilityModule,
  },
  plugins: [vuexPersist.plugin],
});






























































































































import {
  defineComponent,
  ref,
  watch,
  reactive,
  SetupContext,
  computed,
  nextTick,
} from "@vue/composition-api";
import i18n from "@/plugins/i18n";

export default defineComponent({
  name: "AutocompleteInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    value: [Number, String, Array, Object],
    items: [Array],
    name: String,
    filled: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: String,
    required: Boolean,
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: Boolean,
    clearable: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    hint: String,
    loading: {
      type: Boolean,
      default: false,
    },
    eager: {
      type: Boolean,
      default: true,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    itemText: String,
    hideNoData: Boolean,
    itemValue: String,
    autocomplete: String,
    ariaDescribedBy: String,
    errorMessages: [String, Array],
    tabindex: Number,
    customListItem: {
      type: [Boolean, String],
    },
    separateListsNumber: {
      type: [Boolean, Number],
      default: false,
    },
    placeholder: String,
    customChip: Boolean,
    ariaLabel: String,
    ariaRequired: Boolean,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setup(props: any, { emit, refs }: SetupContext) {
    const innerValue = props.returnObject
      ? reactive({ ...props.value })
      : ref(props.value);

    const setCloseButtonLabel = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const elementRef = refs.autoCompleteElement as any;
      const closeButton =
        elementRef.$el.childNodes[0].getElementsByClassName("mdi-close")[0];
      closeButton.ariaLabel = i18n.tc("GENERAL.BUTTONS.AUTOCOMPLETE_CLEAR");
    };

    watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
          nextTick(() => {
            if (newVal && props.clearable) {
              setCloseButtonLabel();
            }
          });
          // Vuetify onChange nem fut le, ha kódból állítjuk be az input értékét.
          emit("change", innerValue.value);
        }
      }
    );
    const invalid = computed(() => {
      if (props.errorMessages === undefined) {
        return false;
      }
      return (props.errorMessages.length > 0).toString();
    });
    const onInput = (
      val: string | number | string[] | number[] | { [key: string]: never }
    ) => {
      emit("input", val);
    };
    const onBlur = () => {
      if (innerValue.value && !props.multiple) {
        emit("input", innerValue.value);
      }
    };

    const onKeyUp = (ev: KeyboardEvent) => {
      const value = (ev.target as HTMLInputElement).value;
      if (ev.key === "Enter") {
        emit("input", innerValue.value);
      }
      if (!value.length && !props.multiple) {
        emit("input", "");
      }
    };

    const removeChip = (item: number) => {
      const array = innerValue.value as number[];
      const index = array.indexOf(item);
      if (index >= 0) array.splice(index, 1);
      emit("input", array);
    };

    const clear = () => {
      innerValue.value = "";
    };

    return {
      innerValue,
      onInput,
      onKeyUp,
      onBlur,
      removeChip,
      clear,
      invalid,
    };
  },
});

import { Content, FaqAnswersMapObject } from "@/core/dataTypes/types";
import store from "@/store";

const baseContent = (): Content | undefined => store.state.faq?.baseContent;
const baseContentLoading = (): boolean | undefined =>
  store.state.faq?.baseContentLoading;
const getBaseContent = (slug: string): Promise<void> =>
  store.dispatch("faq/getBaseContent", slug);

const eMatricaFaqList = (): Content[] | undefined =>
  store.state.faq?.eMatricaFaqList;
const eMatricaFaqListLoading = (): boolean | undefined =>
  store.state.faq?.eMatricaFaqListLoading;
const getEMatricaFaqList = (): Promise<void> =>
  store.dispatch("faq/getEMatricaFaqList");

const eUtdijFaqList = (): Content[] | undefined =>
  store.state.faq?.eUtdijFaqList;
const eUtdijFaqListLoading = (): boolean | undefined =>
  store.state.faq?.eUtdijFaqListLoading;
const getEUtdijFaqList = (): Promise<void> =>
  store.dispatch("faq/getEUtdijFaqList");

const faqAnswers = (): FaqAnswersMapObject | undefined =>
  store.state.faq?.faqAnswers;
const faqAnswersLoading = (): boolean | undefined =>
  store.state.faq?.faqAnswersLoading;
const getFaqAnswer = (slug: string): Promise<void> =>
  store.dispatch("faq/getFaqAnswer", slug);

const resetModule = (): Promise<void> => store.dispatch("faq/resetModule");

export const faqFacade = {
  baseContent,
  baseContentLoading,
  getBaseContent,
  eMatricaFaqList,
  eMatricaFaqListLoading,
  getEMatricaFaqList,
  eUtdijFaqList,
  eUtdijFaqListLoading,
  getEUtdijFaqList,
  faqAnswers,
  faqAnswersLoading,
  getFaqAnswer,
  resetModule,
};

import store from "@/store";

const openedDialogCount = (): number | undefined =>
  store.state.accessibility?.openedDialogCount;
const hasDialogOpened = (): boolean =>
  store.getters["accessibility/hasDialogOpened"];
const setOpenedDialogCount = (count: number): Promise<void> =>
  store.dispatch("accessibility/setOpenedDialogCount", count);
const resetOpenedDialogCount = (): Promise<void> =>
  store.dispatch("accessibility/resetOpenedDialogCount");

export const accessibilityFacade = {
  openedDialogCount,
  hasDialogOpened,
  setOpenedDialogCount,
  resetOpenedDialogCount,
};
























































































































































































import {
  defineComponent,
  computed,
  PropType,
  ref,
  Ref,
} from "@vue/composition-api";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import getISODay from "date-fns/getISODay";
import { CustomerServiceDetail } from "@/core/dataTypes/types";
import { customerServiceOpeningHourLabels } from "@/core/utils/components/customerServiceOpeningHours.util";
import OpeningHours from "@/components/openingHours/OpeningHours.vue";

export default defineComponent({
  name: "CustomerServiceInformation",
  components: {
    HTMLContentRenderer,
    OpeningHours,
  },
  props: {
    details: {
      type: Object as PropType<CustomerServiceDetail>,
      required: true,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const openingHoursPanelIsOpen: Ref<boolean> = ref(false);
    const actualDay = getISODay(new Date()) - 1;
    const openingHourLabels = computed(() =>
      customerServiceOpeningHourLabels(props.details.contactOpeningHours)
    );

    return {
      actualDay,
      openingHourLabels,
      openingHoursPanelIsOpen,
    };
  },
});

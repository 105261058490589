


































































































































































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  SetupContext,
} from "@vue/composition-api";
import VehicleAddForm from "@/components/VehicleAddForm/VehicleAddForm.vue";
import StickerCard from "@/components/StickerCard/StickerCard.vue";
import {
  getCountryCodeError,
  getCountryCodes,
  plateNumberValid,
  postHighwaySticker,
} from "@/core/api/accountService.api";
import { Subscription } from "rxjs";
import i18n from "@/plugins/i18n";
import {
  CartElement,
  CartHighwayStickerQueryResponse,
  CountryCode,
} from "@/core/dataTypes/types";
import {
  ematricaBuyLinks,
  ematricaLink,
} from "@/core/utils/languageHandler.util";
import SnackbarService from "@/core/services/snackbar.service";
import { getRecaptchaToken } from "@/core/utils/captcha";

export default defineComponent({
  name: "StickerQueryPage",
  components: {
    VehicleAddForm,
    StickerCard,
  },
  setup(props: any, { refs }: SetupContext) {
    const stickerCardItems = ref<CartElement[] | unknown>();
    const isStickerLoading = ref(false);
    const page = ref(1);
    const flags = ref([]);
    const isFlagLoading = ref(true);
    const isValidationPlateNumber = ref(false);
    const stickerFlag = ref("");
    const pageCount = ref(0);
    const totalVisible = ref(7);
    const totalRow = ref(0);
    const searchNow = ref();
    const paginationRows = ref(6);
    const noSticker = ref(false);
    const ematricaBuyLink = ematricaLink(ematricaBuyLinks);
    const subTitle = i18n.tc("STICKER_QUERY.SUBTITLE1");
    const plateNumber = ref("");
    const countryCodeErrorSubscription = new Subscription();
    const formValues = reactive({
      countryCode: "",
      plateNumber: "",
    });

    const onPlateNumberValidationBlurred = () => {
      if (formValues.plateNumber) {
        validatePlateNumber();
      }
    };

    // VALIDATION
    const validatePlateNumber = (): Promise<boolean> => {
      resetErrors();
      isValidationPlateNumber.value = true;
      return plateNumberValid({
        plateNumber: formValues.plateNumber,
        countryCode: formValues.countryCode,
      })
        .then((response) => {
          isValidationPlateNumber.value = false;
          if (response.validationResult === "INVALID_PLATE_NUMBER") {
            (refs.form as any).setErrors(setErrorObject(true));
            return false;
          } else {
            (refs.form as any).setErrors(setErrorObject(false));
            return true;
          }
        })
        .catch((error) => {
          isValidationPlateNumber.value = false;
          throw Error(error);
        });
    };

    // ERROR HANDLERS
    const resetErrors = () => {
      (refs.form as any).setErrors(setErrorObject(false));
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const setErrorObject = (valid: boolean): any => {
      const errorObject: any = {};
      errorObject[i18n.tc("STICKER_QUERY.FIELDS.PLATE_NUMBER")] = valid
        ? i18n.tc("VALIDATION.REGEX", 0, {
            name: i18n.tc("STICKER_QUERY.FIELDS.PLATE_NUMBER"),
          })
        : null;
      return errorObject;
    };

    // Submit
    const submitVehicleNewForm = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stickerFlag.value = flags.value.find(
        (value: CountryCode) => value.countryCode === formValues.countryCode
      ) as any;
      searchNow.value = new Date();
      validatePlateNumber().then((isValid) => {
        if (isValid) {
          getHighwayStickerQuery(1);
        }
      });
    };

    const getHighwayStickerQuery = (page: number) => {
      // Captcha token kérés
      isStickerLoading.value = true;
      noSticker.value = false;
      plateNumber.value = formValues.plateNumber;
      getRecaptchaToken("stickerQuery").then((token: string) => {
        const data = {
          plateNumber: formValues.plateNumber,
          countryCode: formValues.countryCode,
          paginationParams: {
            rows: paginationRows.value,
            page: page,
          },
          recaptchaResponseToken: token,
        };
        // Sticker lista lekérés
        postHighwaySticker(data)
          .then((res: CartHighwayStickerQueryResponse) => {
            isStickerLoading.value = false;
            stickerCardItems.value = res.rowList?.highwayStickerQueryRow;
            pageCount.value = res.paginationParams.maxPage;
            totalRow.value = res.paginationParams.totalRows;
          })
          .catch((error) => {
            if (
              error.response.data.faultType === "PLATE_NUMBER_NO_INFORMATION"
            ) {
              noSticker.value = true;
              stickerCardItems.value = [];
              /*SnackbarService.open({
                text: i18n.t(
                  "ERROR_HANDLER.PLATE_NUMBER_NO_INFORMATION"
                ) as string,
              });*/
            } else {
              SnackbarService.open({
                text: i18n.t("ERROR_HANDLER.INVALID_INPUT") as string,
              });
            }
            isStickerLoading.value = false;
            throw Error(error);
          });
      });
    };
    const onChangePagination = () => {
      getHighwayStickerQuery(page.value);
    };

    onMounted(() => {
      // Zászlók lekérése
      getCountryCodes().then((res) => {
        isFlagLoading.value = false;
        flags.value = res;
      });

      // rendszám error feliratkozás
      countryCodeErrorSubscription.add(
        getCountryCodeError().subscribe(() => {
          const errorObject: any = {};
          errorObject[i18n.tc("STICKER_QUERY.FIELDS.COUNTRY_CODE")] = i18n.tc(
            "HTTP_ERRORS.INVALID_COUNTRY_CODE"
          );
          (refs.form as any).setErrors(errorObject);
        })
      );
    });

    onUnmounted(() => {
      countryCodeErrorSubscription.unsubscribe();
    });
    return {
      formValues,
      isStickerLoading,
      stickerCardItems,
      page,
      pageCount,
      totalRow,
      totalVisible,
      flags,
      stickerFlag,
      searchNow,
      ematricaBuyLink,
      isFlagLoading,
      paginationRows,
      isValidationPlateNumber,
      subTitle,
      plateNumber,
      onPlateNumberValidationBlurred,
      submitVehicleNewForm,
      onChangePagination,
      noSticker,
    };
  },
});





































import { defineComponent, PropType } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import CustomerServiceInformation from "@/views/customerService/components/customerServiceInformation/CustomerServiceInformation.vue";
import { CustomerServiceDetail } from "@/core/dataTypes/types";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";

export default defineComponent({
  name: "CustomerServiceInformationDialog",
  components: {
    BaseDialog,
    CustomerServiceInformation,
  },
  props: {
    details: {
      type: Object as PropType<CustomerServiceDetail>,
      required: true,
    },
  },
  setup(props, ctx) {
    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    return {
      closeDialog,
    };
  },
});

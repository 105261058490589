import { FitBoundsOptions } from "leaflet";
import { Breakpoint } from "vuetify/types/services/breakpoint";

export const mapInitialOption: FitBoundsOptions = {
  paddingTopLeft: [0, 140],
};

export const mapPointSelectedOption = (
  breakpoint: Breakpoint
): FitBoundsOptions => {
  if (breakpoint.xs) {
    return {
      paddingTopLeft: [0, 60],
      paddingBottomRight: [0, 216],
      maxZoom: 13,
    };
  } else if (breakpoint.lgAndUp) {
    return {
      paddingTopLeft: [445, 0],
      maxZoom: 13,
    };
  } else {
    return {
      paddingTopLeft: [310, 0],
      maxZoom: 13,
    };
  }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-carousel',{staticClass:"carousel",class:{ 'carousel--bottom-control-on-mobile': _vm.bottomControlOnMobile },attrs:{"hide-delimiters":"","cycle":_vm.startStop,"interval":_vm.interval,"continuous":true,"height":"auto","aria-roledescription":_vm.$t('SLIDESHOW.ACCESSIBILITY.ROLE'),"aria-label":_vm.$t('SLIDESHOW.ACCESSIBILITY.ROLE')},on:{"change":_vm.changeCarousel}},[_vm._l((_vm.dataList),function(item){return _c('v-carousel-item',{key:item.id},[(item.media.mime.startsWith('image'))?_c('v-sheet',{staticClass:"carousel__item carousel__item--image pt-4 pb-18 pb-sm-4",style:({
        'background-color': _vm.coverImage ? 'none' : 'black',
        'background-size': _vm.coverImage ? 'cover' : 'contain',
        'background-image': _vm.useLandscapeFormat
          ? ("url(" + (item.media.thumbnails.landscape[2]) + ")")
          : ("url(" + (item.media.url) + ")"),
      }),attrs:{"height":"100%","role":!item.targetUrl && 'img',"aria-label":!item.targetUrl && item.lead}},[(!!item.targetUrl)?_c('v-row',{staticClass:"fill-height no-gutters",attrs:{"align":"center","justify":"center","tag":"a","href":item.targetUrl,"aria-label":item.lead,"target":"_blank"}}):_vm._e()],1):_vm._e(),(item.media.mime.startsWith('video'))?_c('v-sheet',{staticClass:"h-100 carousel__item pt-4 pb-18 pb-sm-4",attrs:{"aria-label":!item.targetUrl && item.lead,"role":!item.targetUrl && 'img'}},[_c('v-row',{staticClass:"fill-height no-gutters",attrs:{"align":"center","justify":"center","tag":!!item.targetUrl ? 'a' : 'div',"href":item.targetUrl,"aria-label":item.lead,"aria-hidden":!item.targetUrl,"target":"_blank"}},[_c('video',{staticClass:"carousel__item__video position-absolute w-100 h-100",attrs:{"autoplay":"","muted":"","preload":"auto","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.useLandscapeFormat
                ? item.media.thumbnails.landscape[2]
                : item.media.url,"type":item.media.mime}}),_vm._v(" "+_vm._s(_vm.$t("SLIDESHOW.VIDEO_NOT_SUPPORTED"))+" ")])])],1):_vm._e()],1)}),_c('div',{staticClass:"carousel__button-group position-absolute"},[(_vm.cycle)?_c('v-btn',{staticClass:"carousel__button-group__btn white--text mr-2",attrs:{"fab":"","height":"48","width":"48","aria-label":_vm.startStop
          ? _vm.$t('SLIDESHOW.ACCESSIBILITY.PAUSE')
          : _vm.$t('SLIDESHOW.ACCESSIBILITY.PLAY')},on:{"click":_vm.slideStopStart}},[_c('v-icon',[_vm._v(_vm._s(_vm.startStop ? "mdi-pause" : "mdi-play"))])],1):_vm._e(),(_vm.fullscreen)?_c('v-btn',{staticClass:"carousel__button-group__btn white--text",attrs:{"fab":"","height":"48","width":"48"},on:{"click":_vm.openImageOnFullscreen}},[_c('v-icon',[_vm._v("mdi-fullscreen")])],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }













































































import {
  computed,
  onMounted,
  ref,
  SetupContext,
  watch,
  nextTick,
} from "@vue/composition-api";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TextInput",
  props: {
    label: String,
    value: [Number, String],
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    ariaLabel: {
      type: String,
      required: false,
    },
    ariaDescribedBy: String,
    ariaLive: String,
    hint: String,
    persistentHint: Boolean,
    placeholder: String,
    type: {
      type: String,
      default: "text",
    },
    appendOuterIcon: String,
    appendInnerIcon: String,
    autofocus: Boolean,
    dense: Boolean,
    clearable: Boolean,
    clearIcon: {
      type: String,
      default: "$clear",
    },
    counter: [Boolean, Number, String],
    counterValue: Function,
    filled: {
      type: Boolean,
      default: true,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    fullWidth: Boolean,
    outlined: Boolean,
    prefix: String,
    prependInnerIcon: String,
    reverse: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    singleLine: Boolean,
    solo: {
      type: Boolean,
      default: false,
    },
    soloInverted: Boolean,
    suffix: String,
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    tabindex: Number,
    // custom props
    showPasswordEye: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    rules: Array,
    errorMessages: [Array, String],
    required: {
      type: Boolean,
      default: false,
    },
    hungarienTaxNumberFormat: {
      type: Boolean,
      default: false,
    },
    onlyNumberFormat: {
      type: Boolean,
      default: false,
    },
    onlyUpperCase: {
      type: Boolean,
      default: false,
    },
    plateNumberFormat: {
      type: Boolean,
      default: false,
    },
    autocomplete: String,
    inputmode: String,
    readOnly: Boolean,
    loading: Boolean,
  },
  setup(props, { emit, refs }: SetupContext) {
    const inputType = ref(props.showPasswordEye ? "password" : props.type);
    const showPassword = ref(false);
    const innerValue = ref(props.value);
    const isShowPasswordEye = ref(false);
    const appendIcon = ref(
      props.showPasswordEye ? "mdi-eye-off" : props.appendInnerIcon
    );

    const convertHungarianTaxNumberFormat = (value: string): string =>
      value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{8})(\d{1})(\d{2})?/g, "$1-$2-$3");
    const convertNumberFormat = (value: string): string =>
      value.replace(/[^0-9]/g, "");
    /*const convertPlateNumberFormat = (value: string): string =>
      value.replace(/[^0-9A-Za-z]/g, "");*/

    onMounted(() => {
      nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const elementRef = refs.textInputElement as any;
        const messagesWrapper =
          elementRef.$el.childNodes[0].getElementsByClassName(
            "v-messages__wrapper"
          )[0];
        if (messagesWrapper) {
          messagesWrapper.setAttribute("role", "alert");
        }
      });
    });

    watch(
      () => props.value,
      (newVal, oldVal) => {
        if (oldVal !== newVal) {
          innerValue.value = newVal;
        }
      }
    );

    const stopFirstValueWatch = watch(
      () => props.value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          emit("input", newVal);
          stopFirstValueWatch();
        }
      }
    );

    const onPaste = (e: ClipboardEvent) => {
      const value = e.clipboardData?.getData("text") as string;
      if (props.hungarienTaxNumberFormat) {
        e.preventDefault();
        innerValue.value = convertHungarianTaxNumberFormat(value);
        emit("input", innerValue.value);
      } else if (props.onlyNumberFormat) {
        e.preventDefault();
        innerValue.value = convertNumberFormat(value);
        emit("input", innerValue.value);
      } /*else if (props.plateNumberFormat) {
        e.preventDefault();
        innerValue.value = convertPlateNumberFormat(value).toUpperCase();
        emit("input", innerValue.value);
      }*/
    };

    const onInput = (value: string) => {
      let inputValue = value;
      /*if (value.length && props.onlyNumberFormat) {
        inputValue = convertNumberFormat(value as string);
      }*/
      if (value.length && props.onlyUpperCase) {
        inputValue = value.toUpperCase();
        innerValue.value = inputValue;
      }
      innerValue.value = inputValue;
      emit("input", innerValue.value);
      emit("change");
    };

    const onKeyPress = (e: KeyboardEvent) => {
      /*if (props.plateNumberFormat && !/^[0-9A-Z]+$/.test(e.key.toUpperCase())) {
        e.preventDefault();
      }*/
      if (
        (props.onlyNumberFormat || props.hungarienTaxNumberFormat) &&
        !/^[0-9]+$/.test(e.key)
      ) {
        e.preventDefault();
      }
      emit("keypress", e);
    };
    const onKeyDown = (e: KeyboardEvent) => {
      emit("keydown", e);
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key !== "Backspace" && props.onlyNumberFormat) {
        innerValue.value = convertNumberFormat(
          (e.target as HTMLInputElement).value
        );
      }
      if (e.key !== "Backspace" && props.hungarienTaxNumberFormat) {
        innerValue.value = convertHungarianTaxNumberFormat(
          (e.target as HTMLInputElement).value
        );
      }
      /*if (e.key !== "Backspace" && props.plateNumberFormat) {
        innerValue.value = convertPlateNumberFormat(
          (e.target as HTMLInputElement).value
        );
      }*/
      emit("keyup", e);
    };
    const onBlur = (e: KeyboardEvent) => {
      emit("blur", e);
    };

    const onFocus = (e: KeyboardEvent) => {
      emit("focus", e);
    };

    const invalid = computed(() => {
      if (props.errorMessages === undefined) {
        return false;
      }
      return props.errorMessages.length > 0;
    });

    const onClickAppendIcon = (e: MouseEvent) => {
      e.preventDefault();
      if (props.showPasswordEye) {
        isShowPasswordEye.value = !isShowPasswordEye.value;
        appendIcon.value = isShowPasswordEye.value ? "mdi-eye" : "mdi-eye-off";
        inputType.value = isShowPasswordEye.value ? "text" : "password";
        // const inputRef = document.getElementById(
        //  "loginPage--password"
        // ) as HTMLInputElement;
        // const cursorPosition = inputRef.selectionStart;
        // setTimeout(() => {
        //  inputRef.setSelectionRange(cursorPosition, cursorPosition);
        // });
      }
    };
    return {
      innerValue,
      inputType,
      showPassword,
      appendIcon,
      onClickAppendIcon,
      onInput,
      onKeyPress,
      onKeyDown,
      onKeyUp,
      onBlur,
      onFocus,
      onPaste,
      isShowPasswordEye,
      invalid,
    };
  },
});

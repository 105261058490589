






















import { defineComponent, PropType } from "@vue/composition-api";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import { HTMLContent } from "@/core/dataTypes/types";
export default defineComponent({
  name: "MapDefaultInformation",
  components: {
    HTMLContentRenderer,
  },
  props: {
    content: Array as PropType<HTMLContent[][]>,
    title: String,
  },
  setup() {
    const panel = 0;
    return { panel };
  },
});
















import { defineComponent, computed, ref } from "@vue/composition-api";
import HTMLContentRenderer from "@/components/HTMLContentRenderer/HTMLContentRenderer.vue";
import { faqFacade } from "@/store/modules/faq/faq.facade";

export default defineComponent({
  name: "FaqAnswer",
  components: {
    HTMLContentRenderer,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const faqAnswers = computed(faqFacade.faqAnswers);
    const actualLoading = ref<boolean>(false);

    if (!faqAnswers.value?.[props.item?.slug]) {
      actualLoading.value = true;
      faqFacade
        .getFaqAnswer(props.item?.slug)
        .then()
        .finally(() => {
          actualLoading.value = false;
        });
    }

    return {
      actualLoading,
      faqAnswers,
    };
  },
});

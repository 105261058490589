





















































import {
  defineComponent,
  ref,
  SetupContext,
  watch,
  PropType,
} from "@vue/composition-api";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
  LControlZoom,
} from "vue2-leaflet";
import { Icon } from "leaflet";
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";

export default defineComponent({
  name: "CustomMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
    LControlZoom,
  },
  props: {
    selectedPoint: {
      type: String,
      default: "",
    },
    points: {
      type: Array as PropType<CMSMapPoint[]>,
      default: () => [],
      required: true,
    },
    mapInitialOption: Object as PropType<L.FitBoundsOptions>,
    mapActivePointSelectedOption: Object as PropType<L.FitBoundsOptions>,
  },
  setup(
    props,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { refs, emit }: Omit<SetupContext, "refs"> & { [key: string]: any }
  ) {
    const zoom = ref(8);
    const center: number[] = [47.179, 19.506];
    const url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    const points = ref<L.PointTuple[]>([]);

    //https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
    type D = Icon.Default & {
      _getIconUrl?: string;
    };
    delete (Icon.Default.prototype as D)._getIconUrl;

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    const fitMap = (points: L.PointTuple[], options?: L.FitBoundsOptions) => {
      refs.map.mapObject.fitBounds(points, options);
    };

    const fitMapHandler = (options?: L.FitBoundsOptions) => {
      if (points.value.length) {
        fitMap(points.value, options);
      } else {
        watch(points, (newValue) => {
          fitMap(newValue, options);
          // unsubscribePoints();
        });
      }
    };

    const mapIsReady = () => {
      fitMapHandler(props.mapInitialOption);
    };

    const clickOnMarker = (point: CMSMapPoint) => {
      emit("clickOnMarker", point);
    };

    watch(
      () => props.points,
      (newVal) => {
        points.value = newVal.map((i) => [+i.latitude, +i.longitude]);
        // unsubscribePoints();
      }
    );
    watch(
      () => props.selectedPoint,
      (newVal) => {
        if (newVal) {
          const currentPoint = props.points.find((item) => item.id === newVal);

          fitMap(
            [[Number(currentPoint?.latitude), Number(currentPoint?.longitude)]],
            props.mapActivePointSelectedOption
          );
        } else {
          fitMapHandler(props.mapInitialOption);
        }
      }
    );

    return {
      zoom,
      center,
      url,
      clickOnMarker,
      mapIsReady,
    };
  },
});

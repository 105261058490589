

































import {
  dialogCloseEmitter,
  dialogResultEmitter,
} from "@/core/utils/dialog/dialogEmitter";
import { defineComponent, PropType } from "@vue/composition-api";
import { ConfirmDialogPropsType } from "@/core/dataTypes/types";
import i18n from "@/plugins/i18n";
import { tabFocusService } from "@/core/services/tabFocus.service";

export default defineComponent({
  name: "ConfirmDialog",
  props: {
    dialogProps: {
      type: Object as PropType<ConfirmDialogPropsType>,
      required: true,
    },
  },

  setup(props, ctx) {
    const cancelText = props.dialogProps?.submitText
      ? props.dialogProps?.cancelText
      : i18n.tc("DIALOG.BUTTON.NO");
    const submitText = props.dialogProps?.submitText
      ? props.dialogProps?.submitText
      : i18n.tc("DIALOG.BUTTON.YES");

    const clickYes = (): void => {
      dialogResultEmitter(ctx, { autoClose: true, payload: true });
      tabFocusService.setFocus("nuszBack");
    };

    const clickNo = (): void => {
      dialogCloseEmitter(ctx);
      tabFocusService.setFocus("nuszBack");
    };

    return {
      clickYes,
      clickNo,
      submitText,
      cancelText,
    };
  },
});





































































































































import {
  computed,
  defineComponent,
  PropType,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import MapContainer from "@/components/map/MapContainer/MapContainer.vue";
import AutocompleteInput from "@/components/forms/autocomplete/AutocompleteInput.vue";
import TextInput from "@/components/forms/input/TextInput.vue";
import { salesPointsFacade } from "@/store/modules/salesPoints/salesPoints.facade";
import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";
import {
  mapInitialOption,
  mapPointSelectedOption,
} from "@/core/utils/components/mapOption.util";
import { HTMLContent, SalesPointDetails } from "@/core/dataTypes/types";
import { FitBoundsOptions } from "leaflet";
import SalesPointInformation from "@/views/salesPoints/components/salesPointInformation/SalesPointInformation.vue";
import dialogService from "@/core/services/dialog.service";
import SalesPointInformationDialog from "@/views/salesPoints/components/salesPointInformationDialog/SalesPointInformationDialog.vue";

export default defineComponent({
  name: "SalesPointsMap",
  components: {
    MapContainer,
    AutocompleteInput,
    TextInput,
    SalesPointInformation,
  },
  props: {
    tenant: {
      type: String,
      required: true,
    },
    defaultInformationContent: Array as PropType<HTMLContent[][]>,
    defaultInformationTitle: String,
  },
  setup(props, { root }) {
    const advancedFilterIsOpen = ref(false);
    const advancedFilter = reactive({
      filter: {
        locationCountry: "",
        locationCounty: "",
        locationCity: "",
      },
    });
    const salesPoints = computed<CMSMapPoint[] | undefined>(
      salesPointsFacade.salesPoints
    );
    const salesPointsLoading = computed<boolean | undefined>(
      salesPointsFacade.salesPointsLoading
    );
    const salesPointsDetails = computed<SalesPointDetails | undefined>(
      salesPointsFacade.salesPointDetails
    );
    const mapActivePointSelectedOption = computed<FitBoundsOptions>(() =>
      mapPointSelectedOption(root.$vuetify.breakpoint)
    );
    const selectedSalesPoint = ref<string>();

    const selectSalesPoint = (selectedItem: CMSMapPoint) => {
      selectedSalesPoint.value = selectedItem.id;
    };
    const onOpenAdvancedSearch = () => {
      advancedFilterIsOpen.value = !advancedFilterIsOpen.value;
    };

    watch(selectedSalesPoint, (newValue) => {
      if (newValue) {
        salesPointsFacade.resetSalesPointDetails();
        salesPointsFacade.getSalesPointDetails(props.tenant, newValue);

        if (root.$vuetify.breakpoint.xsOnly) {
          dialogService
            .open<{ tenant: string }>({
              fullscreen: true,
              hideOverlay: true,
              component: SalesPointInformationDialog,
              componentProps: { tenant: props.tenant },
            })
            .$on("dialogClose", () => {
              selectedSalesPoint.value = "";
            });
        }
      }
    });

    const advancedFilterSubmit = () => {
      getSalesPoints();
    };

    const advancedFilterDelete = () => {
      selectedSalesPoint.value = "";
      advancedFilter.filter = {
        locationCountry: "",
        locationCounty: "",
        locationCity: "",
      };
      getSalesPoints();
    };

    const getSalesPoints = () => {
      salesPointsFacade.getSalesPoints(props.tenant, {
        take: 2000,
        skip: 0,
        ...advancedFilter,
      });
    };
    getSalesPoints();
    return {
      mapInitialOption,
      advancedFilterIsOpen,
      advancedFilter,
      salesPoints,
      salesPointsLoading,
      mapActivePointSelectedOption,
      selectedSalesPoint,
      salesPointsDetails,
      selectSalesPoint,
      onOpenAdvancedSearch,
      advancedFilterSubmit,
      advancedFilterDelete,
      // getSalesPoints,
    };
  },
});

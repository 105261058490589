import { publicControllerApi } from "@/core/api/publicController.api";
import {
  Content,
  ContentsRequestModel,
  FilterModel,
  SortRequestModel,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { Module } from "vuex";

export interface HomeState {
  highlightedNews: Content[];
  simpleNews: Content[];
  newsLoading: boolean;
  otherLanguageContent: Content;
  otherLanguageContentLoading: boolean;
}

export const initialHomeState: HomeState = {
  highlightedNews: [],
  simpleNews: [],
  newsLoading: false,
  otherLanguageContent: {} as Content,
  otherLanguageContentLoading: false,
};

export const homeModule: Module<HomeState, AppState> = {
  namespaced: true,
  state: initialHomeState,
  mutations: {
    newsRequest: (state) => {
      state.newsLoading = true;
    },
    newsFail: (state) => {
      state.newsLoading = false;
    },
    newsSuccess: (state, payload) => {
      state.newsLoading = false;
      state.highlightedNews = payload.highlightedNews;
      state.simpleNews = payload.simpleNews;
    },
    otherLanguageContentRequest: (state) => {
      state.otherLanguageContentLoading = true;
    },
    otherLanguageContentSuccess: (state, payload: Content) => {
      state.otherLanguageContent = payload;
      state.otherLanguageContentLoading = false;
    },
    otherLanguageContentFail: (state) => {
      state.otherLanguageContentLoading = false;
    },
  },
  actions: {
    getNewsList: ({ commit, rootState }) => {
      commit("newsRequest");
      const keyValuePairs = rootState.common?.keyValuePairs;
      const payload: ContentsRequestModel<SortRequestModel, FilterModel> = {
        take: 10,
        skip: 0,
        sort: {
          featuredContent: "DESC",
          visibilityFrom: "DESC",
        },
        filter: {
          contentCategoryId: keyValuePairs?.newsCategory.value as string,
          languageCode: rootState.common?.currentLanguage as string,
        },
        contentBodyReturn: false,
      };

      return publicControllerApi
        .getContents(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          const payload = {
            highlightedNews: response.data.slice(0, 4),
            simpleNews: response.data.slice(4, 10),
          };
          commit("newsSuccess", payload);
        })
        .catch((error) => {
          commit("newsFail");
          throw Error(error);
        });
    },
    getOtherLanguageContent: ({ commit, rootState, rootGetters }) => {
      commit("otherLanguageContentRequest");
      return publicControllerApi
        .getContents(
          {
            take: 1,
            skip: 0,
            filter: {
              contentCategoryId:
                rootGetters["common/getOtherLanguageCategoryId"],
              languageCode: rootState.common?.currentLanguage as string,
            },
            contentBodyReturn: true,
          },
          process.env.VUE_APP_TENANT
        )
        .then(({ data }) => {
          commit("otherLanguageContentSuccess", data[0]);
        })
        .catch((error) => {
          commit("otherLanguageContentFail");
          throw Error(error);
        });
    },
  },
};

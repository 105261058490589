import { CMSHoursType } from "@/core/dataTypes/cmsTypes";
import { OpeningHours } from "@/core/dataTypes/types";
import i18n from "@/plugins/i18n";

type DayLabel = { label: string; day: keyof CMSHoursType };

const dayLabels: DayLabel[] = [
  { label: "OPENING_HOURS.DAYS.MO_LABEL", day: "mo" },
  { label: "OPENING_HOURS.DAYS.TU_LABEL", day: "tu" },
  { label: "OPENING_HOURS.DAYS.WE_LABEL", day: "we" },
  { label: "OPENING_HOURS.DAYS.TH_LABEL", day: "th" },
  { label: "OPENING_HOURS.DAYS.FR_LABEL", day: "fr" },
  { label: "OPENING_HOURS.DAYS.SA_LABEL", day: "sa" },
  { label: "OPENING_HOURS.DAYS.SU_LABEL", day: "su" },
];

export const customerServiceOpeningHourLabels = (
  openingHours: OpeningHours
): string[] => {
  return dayLabels.map((dayLabel: DayLabel) => {
    const dayName = i18n.tc(dayLabel.label);
    const openTimeText =
      openingHours.hours && openingHours.hours[dayLabel.day]
        ? openingHours.hours[dayLabel.day]
        : i18n.tc("OPENING_HOURS.CLOSE");

    return `${dayName}, ${openTimeText}`;
  });
};

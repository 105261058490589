































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  onBeforeUnmount,
} from "@vue/composition-api";
import SocialIconList from "@/components/socialIconList/SocialIconList.vue";
import MenuList from "@/components/header/navigation/components/menuList/MenuList.vue";
import { commonFacade } from "@/store/modules/common/common.facade";
import { Language } from "@/core/dataTypes/types";

export default defineComponent({
  name: "MobileMenu",
  components: {
    SocialIconList,
    MenuList,
  },
  props: {
    disableMenuButton: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menuOpen = ref<boolean>(false);
    const menu = computed(commonFacade.menu);
    const menuLoading = computed(commonFacade.menuLoading);
    const tenant = computed(commonFacade.tenant);
    const tenantLoading = computed(commonFacade.tenantLoading);
    const currentLanguage = computed(commonFacade.currentLanguage);

    const languageSelected = (language: Language) => {
      emit("languageSelected", language.code);
    };

    const drawerClose = (event: KeyboardEvent) => {
      if (event.code === "Escape") {
        menuOpen.value = !menuOpen;
      }
    };

    onMounted(() => {
      window.addEventListener("keydown", drawerClose);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("keydown", drawerClose);
    });

    return {
      menuOpen,
      menu,
      menuLoading,
      tenant,
      tenantLoading,
      languageSelected,
      currentLanguage,
    };
  },
});

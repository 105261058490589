import { CategoryNames } from "@/core/dataTypes/enums/CategoryNames.enum";
import { commonFacade } from "@/store/modules/common/common.facade";
import { EFaqTabTypes } from "@/core/dataTypes/enums/FaqTabTypes.enum";
import { computed, Ref, ref } from "@vue/composition-api";
import { RawLocation } from "vue-router";

interface CalculateContentUrlProps {
  menuPath?: string;
  contentCategoryName?: string;
  contentCategoryId?: string;
  slug: string;
  subContent?: boolean;
  id?: string;
}

export const replaceSpecialCharacter = (contentCategoryName: string): string =>
  contentCategoryName
    .normalize("NFD")
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");

export const calculatedContentUrl = ({
  menuPath,
  contentCategoryName,
  contentCategoryId,
  slug,
  subContent,
  id,
}: CalculateContentUrlProps): Ref<RawLocation> => {
  const contentCategories = computed(commonFacade.contentCategories);
  const contentUrl: Ref<RawLocation> = ref("#");
  const currentLanguage = computed(commonFacade.currentLanguage);
  if (menuPath) {
    if (subContent) {
      contentUrl.value = {
        path: `/${currentLanguage.value}/${menuPath}`,
        query: {
          subContent: "true",
          id: id,
        },
      };
    } else {
      contentUrl.value = `/${currentLanguage.value}/${menuPath}`;
    }
  } else {
    const contentCategory = contentCategoryId
      ? contentCategories.value?.find((cat) => cat.id === contentCategoryId)
          ?.name
      : contentCategoryName;

    switch (contentCategory) {
      case CategoryNames.NEWS:
        contentUrl.value = {
          name: "NewsItemPage",
          params: { contentSlug: slug },
        };
        break;
      case CategoryNames.EMATRICA_FAQ:
        contentUrl.value = {
          name: "EmatricaFAQPage",
          query: {
            tab: EFaqTabTypes.E_MATRICA,
            "0": slug,
          },
        };
        break;
      case CategoryNames.HUGO_FAQ:
        contentUrl.value = {
          name: "HuGoFAQPage",
          query: {
            tab: EFaqTabTypes.E_UTDIJ,
            slug: slug,
          },
        };
        break;
      default:
        contentUrl.value = `/${currentLanguage.value}`;
    }
  }
  return contentUrl;
};

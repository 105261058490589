import httpService from "@/core/services/http.service";
import {
  CMSContent,
  CMSContentNavigation,
  CMSContentsResponse,
  CMSKeyValue,
  CMSMediaCollectionResponse,
  CMSPublicMenuItem,
  CMSPublicTenant,
  CMSTagType,
} from "../dataTypes/cmsTypes";
import {
  ContentsRequestModel,
  ContentsResponse,
  Content,
  Menu,
  ContentCategories,
  Tenant,
  KeyValue,
  MediaCollection,
  SortRequestModel,
  FilterModel,
  ContentSearchSort,
  ContentSearchFilter,
} from "../dataTypes/types";
import { publicControllerTransformer } from "../transformers/publicController.transformer";

const getContents = (
  requestData: ContentsRequestModel<SortRequestModel, FilterModel>,
  tenant: string
): Promise<ContentsResponse> =>
  httpService
    .post<CMSContentsResponse>(`/api/public/${tenant}/contents`, requestData)
    .then((response) =>
      publicControllerTransformer.transformContentsResponse(response.data)
    );

const contentSearch = (
  requestData: ContentsRequestModel<ContentSearchSort, ContentSearchFilter>,
  tenant: string
): Promise<ContentsResponse> =>
  httpService
    .post<CMSContentsResponse>(
      `/api/public/${tenant}/contents/search`,
      requestData
    )
    .then((response) =>
      publicControllerTransformer.transformContentsResponse(response.data)
    );

const getTags = (languageCode: string, tenant: string): Promise<CMSTagType[]> =>
  httpService
    .get<CMSTagType[]>(`/api/public/${tenant}/tags/${languageCode}`)
    .then((response) => response.data);

const getKeyValuePairs = (tenant: string): Promise<KeyValue> =>
  httpService
    .get<CMSKeyValue[]>(`/api/public/${tenant}/key-value`)
    .then(({ data }) => publicControllerTransformer.transformKeyValue(data));

const getContentByMenuId = (tenant: string, mid: string): Promise<Content[]> =>
  httpService
    .get<CMSContent[]>(`/api/public/${tenant}/contents/menuitem/${mid}`)
    .then(({ data }) =>
      data.map((item) => publicControllerTransformer.transformContent(item))
    );

const contentById = (tenant: string, id: string): Promise<Content> =>
  httpService
    .get<CMSContent>(`/api/public/${tenant}/contents/${id}`)
    .then((response) =>
      publicControllerTransformer.transformContent(response.data)
    );

const contentBySlug = (tenant: string, contentSlug: string): Promise<Content> =>
  httpService
    .get<CMSContent>(`/api/public/${tenant}/contents/slug/${contentSlug}`)
    .then((response) =>
      publicControllerTransformer.transformContent(response.data)
    );

const getAllMenu = (tenant: string, language: string): Promise<Menu> =>
  httpService
    .get<CMSPublicMenuItem[]>(`/api/public/${tenant}/menu-items/${language}`)
    .then((response) =>
      publicControllerTransformer.transformMenu(response.data)
    );

const getContentCategories = (tenant: string): Promise<ContentCategories[]> =>
  httpService
    .get<ContentCategories[]>(`/api/public/${tenant}/content-categories`)
    .then((response) => response.data);

const getTenant = (tenant: string): Promise<Tenant> =>
  httpService
    .get<CMSPublicTenant>(`/api/public/${tenant}/`)
    .then(({ data }) =>
      publicControllerTransformer.transformTenantResponse(data)
    );

const getMediaCollection = (
  tenant: string,
  mediaCollectionID: string
): Promise<MediaCollection> => {
  return httpService
    .get<CMSMediaCollectionResponse>(
      `/api/public/${tenant}/mediacollection/${mediaCollectionID}`
    )
    .then((response) =>
      publicControllerTransformer.transformMediaCollection(response.data)
    );
};

const getMenuContentByPublicRoute = (
  publicRoute: string,
  subContent?: boolean,
  id?: string,
  tenantSlug: string = process.env.VUE_APP_TENANT
): Promise<Content[]> => {
  if (subContent) {
    return httpService
      .get<CMSContent>(`/api/public/${tenantSlug}/contents/${id}`)
      .then(({ data }) => [publicControllerTransformer.transformContent(data)]);
  } else {
    return httpService
      .get<CMSContent[]>(
        `/api/public/${tenantSlug}/contents/menuitem/publicroute/${publicRoute}`
      )
      .then(({ data }) =>
        data.map((item) => publicControllerTransformer.transformContent(item))
      );
  }
};

const getContentNavigation = (
  contentId: string,
  tenantSlug: string = process.env.VUE_APP_TENANT
): Promise<CMSContentNavigation> =>
  httpService
    .get<CMSContentNavigation>(
      `/api/public/${tenantSlug}/contents/navigation/${contentId}`
    )
    .then(({ data }) => data);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getInformationPanelContent = (keyValue: string, lang: string): any => {
  const requestData = {
    take: 10,
    skip: 0,
    sort: { contentCategoryOrder: "ASC" },
    filter: {
      contentCategoryId: keyValue,
      languageCode: lang,
    },
    contentBodyReturn: true,
  };
  return httpService
    .post<CMSContentsResponse>(
      `/api/public/${process.env.VUE_APP_TENANT}/contents`,
      requestData
    )
    .then((response) => response.data);
};

export const publicControllerApi = {
  getKeyValuePairs,
  contentById,
  contentBySlug,
  getContents,
  getTags,
  getAllMenu,
  getContentCategories,
  getContentByMenuId,
  getTenant,
  getMediaCollection,
  getMenuContentByPublicRoute,
  getContentNavigation,
  contentSearch,
  getInformationPanelContent,
};

import { Content } from "@/core/dataTypes/types";
import store from "@/store";

const searchContentList = (): Content[] | undefined =>
  store.state.search?.searchContentList;
const totalNumberOfItems = (): number | undefined =>
  store.state.search?.totalNumberOfItems;
const searchContentListLoading = (): boolean | undefined =>
  store.state.search?.searchContentListLoading;
const searchContentListPageCount = (): number | undefined =>
  store.state.search?.searchContentListPageCount;
const getSearchContentListByKeyword = (
  page: number,
  keyword?: string
): Promise<void> =>
  store.dispatch("search/getSearchContentListByKeyword", { page, keyword });

const getSearchContentList = (page: number, tagIds?: string[]): Promise<void> =>
  store.dispatch("search/getSearchContentList", { page, tagIds });

export const searchFacade = {
  searchContentList,
  totalNumberOfItems,
  searchContentListLoading,
  searchContentListPageCount,
  getSearchContentListByKeyword,
  getSearchContentList,
};

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import SnackbarService from "./snackbar.service";
import i18n from "@/plugins/i18n";

class HttpService {
  http: AxiosInstance;
  protected source: CancelTokenSource;
  private _isPending = 0;

  constructor() {
    this.http = axios.create({
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "false",
      },
      baseURL: process.env.VUE_APP_CMS_URL,
    });

    this.source = axios.CancelToken.source();

    this.http.interceptors.request.use(
      async (request) => {
        this._isPending++;
        return this._createRequestConfig(request);
      },
      () => {
        this._decreasePending();
        SnackbarService.open({
          text: i18n.t("HTTP_ERROR.DEFAULT") as string,
        });
      }
    );
    this.http.interceptors.response.use(
      (response) => {
        this._decreasePending();
        return response;
      },
      (error) => {
        this._decreasePending();
        SnackbarService.open({
          text: i18n.t("HTTP_ERROR.DEFAULT") as string,
        });
        return Promise.reject(error);
      }
    );
  }

  get<R>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.http.get(url, {
      ...config,
    });
  }

  post<R, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.post(url, data, {
      ...config,
    });
  }

  put<R, D = unknown>(
    url: string,
    data: D,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.put(url, data, {
      ...config,
    });
  }

  delete<R>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.http.delete(url, {
      ...config,
    });
  }

  isPending(): boolean {
    return !!this._isPending;
  }

  cancel(): void {
    this.source.cancel("http kérés megszakítva");
    this.source = axios.CancelToken.source();
  }

  private _decreasePending(): void {
    this._isPending > 0 ? this._isPending-- : undefined;
  }

  private _createRequestConfig(
    request: AxiosRequestConfig
  ): AxiosRequestConfig {
    const requestConfig = {
      ...request,
      cancelToken: this.source.token,
    };

    return requestConfig;
  }
}

export default new HttpService();

import { AppState } from "@/store";
import { publicControllerApi } from "@/core/api/publicController.api";
import {
  ContentCategories,
  Language,
  Menu,
  Content,
  Tenant,
  KeyValue,
} from "@/core/dataTypes/types";
import { Module } from "vuex";
import { CategoryNames } from "@/core/dataTypes/enums";
export interface CommonState {
  keyValuePairs: KeyValue;
  keyValuePairsLoading: boolean;
  tenant: Tenant;
  tenantLoading: boolean;
  menu: Menu;
  menuLoading: boolean;
  contentCategories: ContentCategories[];
  contentCategoriesLoading: boolean;
  cookieContent: Content;
  cookieContentLoading: boolean;
  currentLanguage: string;
  contactContent: Content;
  contactContentLoading: boolean;
}

export const initialCommonState: CommonState = {
  keyValuePairs: {} as KeyValue,
  keyValuePairsLoading: false,
  tenant: {} as Tenant,
  tenantLoading: false,
  menu: {} as Menu,
  menuLoading: false,
  contentCategories: [],
  contentCategoriesLoading: false,
  cookieContent: {} as Content,
  cookieContentLoading: false,
  currentLanguage: process.env.VUE_APP_I18N_LOCALE,
  contactContent: {} as Content,
  contactContentLoading: false,
};

export const commonModule: Module<CommonState, AppState> = {
  namespaced: true,
  state: initialCommonState,
  mutations: {
    keyValuePairRequest: (state) => {
      state.keyValuePairsLoading = true;
    },
    keyValuePairSuccess: (state, payload: KeyValue) => {
      state.keyValuePairs = payload;
      state.keyValuePairsLoading = false;
    },
    keyValuePairFail: (state) => {
      state.keyValuePairsLoading = false;
    },
    tenantRequest: (state) => {
      state.tenantLoading = true;
    },
    tenantSuccess: (state, payload: Tenant) => {
      state.tenant = payload;
      state.tenantLoading = false;
    },
    tenantFail: (state) => {
      state.tenantLoading = false;
    },
    menuRequest: (state) => {
      state.menuLoading = true;
    },
    menuSuccess: (state, payload: Menu) => {
      state.menu = payload;
      state.menuLoading = false;
    },
    menuFail: (state) => {
      state.menuLoading = false;
    },
    menuReset: (state) => {
      state.menu = {};
    },
    contentCategoriesRequest: (state) => {
      state.contentCategoriesLoading = true;
    },
    contentCategoriesSuccess: (state, payload: ContentCategories[]) => {
      state.contentCategories = payload;
      state.contentCategoriesLoading = false;
    },
    contentCategoriesFail: (state) => {
      state.contentCategoriesLoading = false;
    },

    cookieContentRequest: (state) => {
      state.cookieContentLoading = true;
    },
    cookieContentSuccess: (state, payload: Content) => {
      state.cookieContent = payload;
      state.cookieContentLoading = false;
    },
    cookieContentFail: (state) => {
      state.cookieContentLoading = false;
    },
    resetCookieContent: (state) => {
      state.cookieContent = {} as Content;
    },
    currentLanguage: (state, payload: string) => {
      state.currentLanguage = payload;
    },
    contactContentRequest: (state) => {
      state.contactContentLoading = true;
    },
    contactContentSuccess: (state, payload: Content) => {
      state.contactContent = payload;
      state.contactContentLoading = false;
    },
    contactContentFail: (state) => {
      state.contactContentLoading = false;
    },
    resetContactContent: (state) => {
      state.contactContent = {} as Content;
    },
  },
  actions: {
    getKeyValuePairs: ({ commit }) => {
      commit("keyValuePairRequest");
      return publicControllerApi
        .getKeyValuePairs(process.env.VUE_APP_TENANT)
        .then((payload) => {
          commit("keyValuePairSuccess", payload);
        })
        .catch((error) => {
          commit("keyValuePairFail");
          throw Error(error);
        });
    },
    getTenant: ({ commit }) => {
      commit("tenantRequest");
      return publicControllerApi
        .getTenant(process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("tenantSuccess", response);
        })
        .catch((error) => {
          commit("tenantFail");
          throw Error(error);
        });
    },
    getMenu: ({ commit, state }) => {
      commit("menuRequest");
      return publicControllerApi
        .getAllMenu(process.env.VUE_APP_TENANT, state.currentLanguage)
        .then((response) => {
          commit("menuSuccess", response);
        })
        .catch((error) => {
          commit("menuFail");
          throw Error(error);
        });
    },
    resetMenu: ({ commit }) => {
      commit("menuReset");
    },
    getContentCategories: ({ commit }) => {
      commit("contentCategoriesRequest");
      return publicControllerApi
        .getContentCategories(process.env.VUE_APP_TENANT)
        .then((slug) => {
          commit("contentCategoriesSuccess", slug);
        })
        .catch((error) => {
          commit("contentCategoriesFail");
          throw Error(error);
        });
    },
    getCookieContent: ({ commit, getters, state }) => {
      commit("cookieContentRequest");
      return publicControllerApi
        .getContents(
          {
            take: 1,
            skip: 0,
            filter: {
              contentCategoryId: getters.getCookieId as string,
              languageCode: state.currentLanguage,
            },
            contentBodyReturn: true,
          },
          process.env.VUE_APP_TENANT
        )
        .then(({ data }) => {
          commit("cookieContentSuccess", data[0]);
        })
        .catch((error) => {
          commit("cookieContentFail");
          throw Error(error);
        });
    },
    resetCookieContent: ({ commit }) => {
      commit("resetCookieContent");
    },

    setCurrentLanguage: ({ commit }, languageCode: string) => {
      commit("currentLanguage", languageCode);
    },

    getContactContent: ({ commit, state, getters }) => {
      commit("contactContentRequest");

      return publicControllerApi
        .getContents(
          {
            take: 1,
            skip: 0,
            filter: {
              contentCategoryId: getters.getContactCategoryId,
              languageCode: state.currentLanguage,
            },
            contentBodyReturn: true,
          },
          process.env.VUE_APP_TENANT
        )
        .then(({ data }) => {
          commit("contactContentSuccess", data[0]);
        })
        .catch((error) => {
          commit("contactContentFail");
          throw Error(error);
        });
    },
    resetContactContent: ({ commit }) => {
      commit("resetContactContent");
    },
  },

  getters: {
    getCookieId: (state) => {
      const cokieID = state.contentCategories.find(
        (item) => item.name === CategoryNames.COOKIE
      )?.id as string;
      return cokieID;
    },
    getContactCategoryId: (state) =>
      state.contentCategories.find(
        (category) => category.name === CategoryNames.CONTENT
      )?.id,
    getOtherLanguageCategoryId: (state) =>
      state.contentCategories.find(
        (category) => category.name === CategoryNames.OTHER_LANGUAGE
      )?.id,
    languageCodes: (state) =>
      state.tenant.languages.reduce(
        (acc: string[], curr: Language): string[] => {
          return [...acc, curr.code];
        },
        []
      ),
  },
};

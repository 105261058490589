












































import { CMSMapPoint } from "@/core/dataTypes/cmsTypes";
import { HTMLContent } from "@/core/dataTypes/types";
import { defineComponent, PropType } from "@vue/composition-api";
import { FitBoundsOptions } from "leaflet";
import CustomMap from "@/components/map/CustomMap/CustomMap.vue";
import MapDefaultInformation from "@/components/map/mapDefaultInformation/MapDefaultInformation.vue";

export default defineComponent({
  name: "MapContainer",
  components: {
    CustomMap,
    MapDefaultInformation,
  },
  props: {
    selectedPoint: {
      type: String,
      default: "",
    },
    points: {
      type: Array as PropType<CMSMapPoint[]>,
      default: () => [],
      required: true,
    },
    mapInitialOption: Object as PropType<FitBoundsOptions>,
    mapActivePointSelectedOption: Object as PropType<FitBoundsOptions>,
    defaultInformationTitle: String,
    defaultInformationContent: Array as PropType<HTMLContent[][]>,
  },
  setup(props, { emit }) {
    const clickOnMarker = (point: CMSMapPoint) => {
      emit("clickOnMarker", point);
    };
    return {
      clickOnMarker,
    };
  },
});

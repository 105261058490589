












































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BaseDialog",
  props: {
    title: String,
    titleBottomBorder: {
      type: Boolean,
      default: false,
    },
    fullDialogOverfowed: {
      type: Boolean,
      default: false,
    },
    fullScreenDialogOnXs: {
      type: Boolean,
      default: true,
    },
    contentAlignCenter: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const closeDialog = () => {
      emit("closeDialog");
    };
    return {
      closeDialog,
    };
  },
});

import { publicControllerApi } from "@/core/api/publicController.api";
import { publicHugoSalesPointControllerApi } from "@/core/api/publicHugoSalesPointController.api";
import { CMSMapPoint, CMSSalesPointsRequest } from "@/core/dataTypes/cmsTypes";
import { Content, SalesPointDetails } from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { cloneDeep } from "lodash/fp";
import { Module } from "vuex";
import SnackbarService from "@/core/services/snackbar.service";
import i18n from "@/plugins/i18n";

export interface SalesPointsState {
  content: Content[];
  contentLoading: boolean;
  salesPoints: CMSMapPoint[];
  salesPointsLoading: boolean;
  salesPointDetails: SalesPointDetails;
  salesPointDetailsLoading: boolean;
}

export const initialSalesPointsState: SalesPointsState = {
  content: [],
  contentLoading: false,
  salesPoints: [],
  salesPointsLoading: false,
  salesPointDetails: {} as SalesPointDetails,
  salesPointDetailsLoading: false,
};

export const salesPointsModule: Module<SalesPointsState, AppState> = {
  namespaced: true,
  state: initialSalesPointsState,
  mutations: {
    contentRequest: (state) => {
      state.contentLoading = true;
    },
    contentSuccess: (state, payload: Content[]) => {
      state.content = payload;
      state.contentLoading = false;
    },
    contentFail: (state) => {
      state.contentLoading = false;
    },
    salesPointsRequest: (state) => {
      state.salesPointsLoading = true;
    },
    salesPointsSuccess: (state) => {
      state.salesPointsLoading = false;
    },
    setSalesPoints: (state, payload: CMSMapPoint[]) => {
      state.salesPoints = payload;
    },
    salesPointsFail: (state) => {
      state.salesPointsLoading = false;
    },
    salesPointDetailsRequest: (state) => {
      state.salesPointDetailsLoading = true;
    },
    salesPointDetailsSuccess: (state, payload: SalesPointDetails) => {
      state.salesPointDetails = payload;
      state.salesPointDetailsLoading = false;
    },
    salesPointDetailsFail: (state) => {
      state.salesPointDetailsLoading = false;
    },
    resetSalesPointDetails: (state) => {
      state.salesPointDetails = {} as SalesPointDetails;
    },
    resetModule: (state) => {
      Object.assign(state, cloneDeep(initialSalesPointsState));
    },
  },
  actions: {
    resetModule: ({ commit }) => {
      commit("resetModule");
    },
    getContent: ({ commit }, slug: string) => {
      commit("contentRequest");
      return publicControllerApi
        .getMenuContentByPublicRoute(slug)
        .then((response) => {
          commit("contentSuccess", response);
        })
        .catch((error) => {
          commit("contentFail");
          throw Error(error);
        });
    },
    getSalesPoints: (
      { commit, rootState },
      {
        tenant,
        requestData,
      }: { tenant: string; requestData: CMSSalesPointsRequest }
    ) => {
      commit("salesPointsRequest");
      return publicHugoSalesPointControllerApi
        .getSalesPoints(
          tenant,
          requestData,
          rootState.common?.currentLanguage as string
        )
        .then((response) => {
          commit("salesPointsSuccess");
          if (response.length) {
            commit("setSalesPoints", response);
          } else {
            SnackbarService.open({
              text: i18n.t("SALES_POINTS.MESSAGE") as string,
            });
          }
        })
        .catch((error) => {
          commit("salesPointsFail");
          throw Error(error);
        });
    },
    getSalesPointDetails: (
      { commit },
      { tenant, id }: { tenant: string; id: string }
    ) => {
      commit("salesPointDetailsRequest");
      return publicHugoSalesPointControllerApi
        .getSalesPointDetails(tenant, id)
        .then((response) => {
          commit("salesPointDetailsSuccess", response);
        })
        .catch((error) => {
          commit("salesPointDetailsFail");
          throw Error(error);
        });
    },
  },
};















import { EhtmlContentTypes } from "@/core/dataTypes/enums/htmlContentTypes.enum";
import router, { useRoute } from "@/router";
import { defineComponent, onMounted } from "@vue/composition-api";
import handleNavigationDuplicated from "@/core/utils/errorHandlers/handleNavigationDuplicated";
import { publicControllerApi } from "@/core/api/publicController.api";
import {
  calculatedContentUrl,
  replaceSpecialCharacter,
} from "@/core/utils/components/calculatedContentUrl.util";

export default defineComponent({
  name: "HTMLContentRenderer",
  props: {
    contents: Object,
    customHtmlContentClass: String,
  },
  setup(props, { root }) {
    const route = useRoute();

    const scroll = (hash: string) => {
      const anchor: HTMLElement | null = document.querySelector(hash);
      const anchorTop = (anchor as HTMLElement).getBoundingClientRect().top;
      const body = document.body;
      const scrollTop = window.pageYOffset || body.scrollTop;
      const clientTop = 200;
      const top = anchorTop + scrollTop - clientTop;

      scrollTo({
        top: top,
        behavior: "smooth",
      });
    };

    const transformAlignName = (alignName: string): string => {
      if (alignName === "right") {
        alignName = "flex-end";
      } else if (alignName === "left") {
        alignName = "flex-start";
      }
      return alignName;
    };

    onMounted(() => {
      const aTags = document.querySelectorAll(
        `.${props.customHtmlContentClass} a`
      );
      const internalLinks = document.querySelectorAll(
        `.${props.customHtmlContentClass} .cms-link-internal`
      );
      const ulTags = document.querySelectorAll(
        `.${props.customHtmlContentClass} ul`
      );
      const olTags = document.querySelectorAll(
        `.${props.customHtmlContentClass} ol`
      );
      if (olTags.length) {
        olTags.forEach((ulTag) => {
          const liTags = ulTag.querySelectorAll("li");
          liTags.forEach((liTag) => {
            const pTag = liTag.querySelector("p") as HTMLElement;
            let alignName = pTag.getAttribute("class");
            liTag.setAttribute(
              "style",
              `align-self: ${transformAlignName(alignName as string)}`
            );
          });
        });
      }
      if (ulTags.length) {
        ulTags.forEach((ulTag) => {
          const liTags = ulTag.querySelectorAll("li");
          liTags.forEach((liTag) => {
            const pTag = liTag.querySelector("p") as HTMLElement;
            let alignName = pTag.getAttribute("class");
            liTag.setAttribute(
              "style",
              `align-self: ${transformAlignName(alignName as string)}`
            );
          });
        });
      }

      aTags.forEach((item) => {
        const href = item.getAttribute("href");
        const img = item.querySelectorAll("img");
        if (!href) {
          // do nothing
          return;
        }
        if (item.getAttribute("data-link-type") === "file" && !img.length) {
          const isHighlighted = item.parentElement?.tagName === "SPAN";
          const newContent = `<div class="file-content"><i class="mr-2 grey--text text--darken-4"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" />
            </svg></i>${
              isHighlighted ? item.parentElement?.outerHTML : item.outerHTML
            }</div>`;

          isHighlighted
            ? ((item.parentElement as HTMLElement).outerHTML = newContent)
            : (item.outerHTML = newContent);
        } else if (href.startsWith("#")) {
          item.addEventListener("click", () => {
            // e.preventDefault();
            if (route.value.hash) {
              scroll(route.value.hash);
            } else {
              router.replace({ hash: href }).catch(handleNavigationDuplicated);
            }
          });
        }
      });

      internalLinks.forEach((item) => {
        const contentId = item.getAttribute("data-id");
        const linkTarget = item.getAttribute("target");
        const linkText = item.getAttribute("title");
        publicControllerApi
          .getContentNavigation(contentId as string)
          .then(({ menuPath, slug, categoryName, languageCode }) => {
            let calculatedPath = calculatedContentUrl({
              menuPath,
              slug,
              contentCategoryName: categoryName,
            }).value;

            if (typeof calculatedPath !== "string") {
              const categoryUrl =
                replaceSpecialCharacter(categoryName).toLocaleLowerCase();
              calculatedPath = `/${languageCode}/${categoryUrl}/${slug}`;
            }

            const link = document.createElement("a");
            // link.setAttribute("href", `/${languageCode}/${menuPath}`);
            link.setAttribute("href", calculatedPath);
            link.setAttribute("target", linkTarget as string);
            link.innerText = linkText as string;
            link.addEventListener("click", (e) => {
              e.preventDefault();
              if (linkTarget === "_blank") {
                window.open(calculatedPath as string);
              } else {
                router.push(calculatedPath).catch(handleNavigationDuplicated);
              }
            });
            item.replaceWith(link);
          });
      });

      root.$nextTick(() => {
        if (route.value.hash) {
          scroll(route.value.hash);
        }
      });
    });

    return {
      EhtmlContentTypes,
    };
  },
});

import { publicControllerApi } from "@/core/api/publicController.api";
import {
  Content,
  ContentsRequestModel,
  FaqAnswersMapObject,
  FilterModel,
  SortRequestModel,
} from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { cloneDeep } from "lodash/fp";
import { Module } from "vuex";

export interface FAQState {
  baseContent: Content;
  baseContentLoading: boolean;
  eMatricaFaqList: Content[];
  eMatricaFaqListLoading: boolean;
  eUtdijFaqList: Content[];
  eUtdijFaqListLoading: boolean;
  faqAnswers: FaqAnswersMapObject;
  faqAnswersLoading: boolean;
}

export const initialFAQState: FAQState = {
  baseContent: {} as Content,
  baseContentLoading: false,
  eMatricaFaqList: [],
  eMatricaFaqListLoading: false,
  eUtdijFaqList: [],
  eUtdijFaqListLoading: false,
  faqAnswers: {} as FaqAnswersMapObject,
  faqAnswersLoading: false,
};

export const faqModule: Module<FAQState, AppState> = {
  namespaced: true,
  state: initialFAQState,
  mutations: {
    baseContentRequest: (state) => {
      state.baseContentLoading = true;
    },
    baseContentSuccess: (state, payload: Content) => {
      state.baseContent = payload;
      state.baseContentLoading = false;
    },
    baseContentFail: (state) => {
      state.baseContentLoading = false;
    },

    eMatricaFaqListRequest: (state) => {
      state.eMatricaFaqListLoading = true;
    },
    eMatricaFaqListSuccess: (state, payload: Content[]) => {
      state.eMatricaFaqList = payload;
      state.eMatricaFaqListLoading = false;
    },
    eMatricaFaqListFail: (state) => {
      state.eMatricaFaqListLoading = false;
    },

    eUtdijFaqListRequest: (state) => {
      state.eUtdijFaqListLoading = true;
    },
    eUtdijFaqListSuccess: (state, payload: Content[]) => {
      state.eUtdijFaqList = payload;
      state.eUtdijFaqListLoading = false;
    },
    eUtdijFaqListFail: (state) => {
      state.eUtdijFaqListLoading = false;
    },

    faqAnswersRequest: (state) => {
      state.faqAnswersLoading = true;
    },
    faqAnswersSuccess: (state, payload: FaqAnswersMapObject) => {
      state.faqAnswers = payload;
      state.faqAnswersLoading = false;
    },
    faqAnswersFail: (state) => {
      state.faqAnswersLoading = false;
    },
    resetModule: (state) => {
      Object.assign(state, cloneDeep(initialFAQState));
    },
  },
  actions: {
    getBaseContent: ({ commit }, slug: string) => {
      commit("baseContentRequest");
      return publicControllerApi
        .getMenuContentByPublicRoute(slug)
        .then((response) => {
          commit("baseContentSuccess", response[0]);
        })
        .catch((error) => {
          commit("baseContentFail");
          throw Error(error);
        });
    },
    getEMatricaFaqList: ({ commit, rootState }) => {
      commit("eMatricaFaqListRequest");
      const keyValuePairs = rootState.common?.keyValuePairs;
      const payload: ContentsRequestModel<SortRequestModel, FilterModel> = {
        take: 1000,
        skip: 0,
        sort: {
          contentCategoryOrder: "ASC",
        },
        filter: {
          contentCategoryId: keyValuePairs?.eVignetteFaqCategory
            .value as string,
          languageCode: rootState.common?.currentLanguage as string,
        },
        contentBodyReturn: false,
      };
      return publicControllerApi
        .getContents(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("eMatricaFaqListSuccess", response.data);
        })
        .catch((error) => {
          commit("eMatricaFaqListFail");
          throw Error(error);
        });
    },
    getEUtdijFaqList: ({ commit, rootState }) => {
      commit("eUtdijFaqListRequest");
      const keyValuePairs = rootState.common?.keyValuePairs;
      const payload: ContentsRequestModel<SortRequestModel, FilterModel> = {
        take: 1000,
        skip: 0,
        sort: {
          contentCategoryOrder: "ASC",
        },
        filter: {
          contentCategoryId: keyValuePairs?.hugoFaqCategory.value as string,
          languageCode: rootState.common?.currentLanguage as string,
        },
        contentBodyReturn: false,
      };
      return publicControllerApi
        .getContents(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("eUtdijFaqListSuccess", response.data);
        })
        .catch((error) => {
          commit("eUtdijFaqListFail");
          throw Error(error);
        });
    },
    getFaqAnswer: ({ commit, state }, contentSlug: string) => {
      commit("faqAnswersRequest");
      return publicControllerApi
        .contentBySlug(process.env.VUE_APP_TENANT, contentSlug)
        .then((response: Content) => {
          const payload: FaqAnswersMapObject = {
            ...state.faqAnswers,
            [response.slug]: response,
          };
          commit("faqAnswersSuccess", payload);
        })
        .catch((error) => {
          commit("faqAnswersFail");
          throw Error(error);
        });
    },
    resetModule: ({ commit }) => {
      commit("resetModule");
    },
  },
};



























































































































































import {
  ematricaLink,
  ematricaLoginLinks,
  ematricaRegistrationLinks,
} from "@/core/utils/languageHandler.util";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "LoginMenu",
  props: {
    disableMobileButton: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { root }) {
    const ematricaLoginLink = ematricaLink(ematricaLoginLinks);
    const ematricaRegistrationLink = ematricaLink(ematricaRegistrationLinks);
    const hugoLoginRegistrationLink = `${process.env.VUE_APP_HUGO_URL}/articles/category/aktualis`;

    const breakpoint = computed(() => root.$vuetify.breakpoint);

    return {
      ematricaLoginLink,
      ematricaRegistrationLink,
      hugoLoginRegistrationLink,
      breakpoint,
    };
  },
});

import i18n from "@/plugins/i18n";

export const EStickerTypeValue = {
  YEARLY_N: `${i18n.tc("STICKER_CARD_LABEL.YEARLY_N")}`,
  YEARLY_C: `${i18n.tc("STICKER_CARD_LABEL.YEARLY_C")}`,
  MONTHLY_N: `${i18n.tc("STICKER_CARD_LABEL.MONTHLY_N")}`,
  _10DAYS_N: `${i18n.tc("STICKER_CARD_LABEL._10DAYS_N")}`,
};

export interface CartElement {
  cartElementId: string;
  cost: number;
  countryCode: string;
  countryIsoCode: string;
  countyName: string | unknown;
  highwayStickerNumber: string;
  plateNumber: string;
  productYear: number;
  stickerId: string;
  stickerType: {
    id: string;
    name: string;
  };
  validityStart: string;
  validityEnd: string;
  vehiclePriceCategory: string;
  loading?: boolean;
}

export interface CartElementList {
  cartElement: CartElement[];
}

export interface CartHistoryQueryRow {
  cartId: string;
  status: string;
  transactionId: string;
  cartElementsCount: number;
  cost: number;
  purchaseDate: string;
  invoiceId: string;
  invoiceNumber: string;
  loading?: boolean;
  cartElementList: CartElementList;
}

export interface CartHighwayStickerQueryResponse {
  paginationParams: {
    rows: number;
    page: number;
    totalRows: number;
    maxPage: number;
  };
  rowList: {
    highwayStickerQueryRow: CartHistoryQueryRow[];
  };
}

export interface CountryCode {
  countryCode: string;
  name: string;
  isoCode: string;
  fullName: string;
}

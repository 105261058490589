import { CMSPublicCustomerService } from "../dataTypes/cmsTypes";
import { CustomerServiceDetail } from "../dataTypes/types";
import { commonTransformer } from "./common.transformer";

const transformCustomerServiceDetail = (
  data: CMSPublicCustomerService
): CustomerServiceDetail => {
  const {
    id,
    name,
    contactEmailSticker,
    contactEmailHugo,
    contactPhoneHu,
    contactPhoneForeign,
    locationAddress,
    locationLatitude,
    locationLongitude,
    locationWheelchairAccessible,
    locationPetFriendly,
  } = data;

  return {
    id,
    name,
    contactEmailSticker,
    contactEmailHugo,
    contactPhoneHu,
    contactPhoneForeign,
    locationAddress,
    locationLatitude,
    locationLongitude,
    locationWheelchairAccessible,
    locationPetFriendly,
    contactOpeningHours: commonTransformer.transformOpeningHours(
      data.contactOpeningHours?.hours
    ),
    ...(data.locationPicture && {
      locationPicture: commonTransformer.transformMediaResponse(
        data.locationPicture
      ),
    }),
    description: commonTransformer.transformHTMLContent(data.description),
  };
};

export const publicHugoCustomerServiceControllerTransformer = {
  transformCustomerServiceDetail,
};








































































































import { customerServiceOpeningHourLabels } from "@/core/utils/components/customerServiceOpeningHours.util";
import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import getISODay from "date-fns/getISODay";
import ExpansionPanelHeader from "@/components/expansionPanel/ExpansionPanelHeader.vue";
import { OpeningHours } from "@/core/dataTypes/types";

export default defineComponent({
  name: "OpeningHours",
  components: {
    ExpansionPanelHeader,
  },
  props: {
    openingHours: {
      type: Object as PropType<OpeningHours>,
      required: true,
    },
  },
  setup(props) {
    const openingHoursPanelIsOpen = ref<boolean>(false);
    const actualDay = getISODay(new Date()) - 1;
    const openingHourLabels = computed(() =>
      customerServiceOpeningHourLabels(props.openingHours)
    );

    return {
      actualDay,
      openingHourLabels,
      openingHoursPanelIsOpen,
    };
  },
});

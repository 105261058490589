import { Module } from "vuex";
import { AppState } from "@/store";

export interface AccessibilityState {
  openedDialogCount: number;
}

export const initialAccessibilityState: AccessibilityState = {
  openedDialogCount: 0,
};

export const accessibilityModule: Module<AccessibilityState, AppState> = {
  namespaced: true,
  state: initialAccessibilityState,
  mutations: {
    setOpenedDialogCount: (state: AccessibilityState, count: number) => {
      state.openedDialogCount = count;
    },
    resetOpenedDialogCount: (state: AccessibilityState) => {
      state.openedDialogCount = initialAccessibilityState.openedDialogCount;
    },
  },
  actions: {
    setOpenedDialogCount: ({ commit }, count) => {
      commit("setOpenedDialogCount", count);
    },
    resetOpenedDialogCount: ({ commit }) => {
      commit("resetOpenedDialogCount");
    },
  },
  getters: {
    hasDialogOpened: (state: AccessibilityState) =>
      (!!state.openedDialogCount).toString(),
  },
};



































import { isEmpty } from "lodash";
import { Subscription } from "rxjs";
import {
  computed,
  defineComponent,
  onUnmounted,
  onMounted,
  ref,
  watch,
} from "@vue/composition-api";
import SkipLink from "@/components/skipLink/SkipLink.vue";
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import CookieAlert from "@/components/cookieAlert/CookieAlert.vue";
import addDays from "date-fns/addDays";
import isAfter from "date-fns/isAfter";
import { commonFacade } from "./store/modules/common/common.facade";
import SnackbarWrapper from "@/components/snackbar/snackbarWrapper/SnackbarWrapper.vue";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { accessibilityFacade } from "./store/modules/accessibility/accessibility.facade";
import { publicControllerApi } from "@/core/api/publicController.api";
import HTMLInfoContentRenderer from "@/components/HTMLInfoContentRenderer/HTMLInfoContentRenderer.vue";
import router from "./router";
import { visibleRecaptchaLogo } from "./core/utils/captcha";
export default defineComponent({
  components: {
    SkipLink,
    Header,
    Footer,
    CookieAlert,
    SnackbarWrapper,
    HTMLInfoContentRenderer,
  },
  setup() {
    const tabFocusSubscription = new Subscription();
    const tenant = computed(commonFacade.tenant);
    const currentLanguage = computed(commonFacade.currentLanguage);
    const cookieAlertVisibility = ref(false);
    const envName = ref(process.env.VUE_APP_ENVIRONMENT);
    const cookieAcceptedTime = localStorage.getItem("nuszCookieAcceptedDate");
    const cookieContent = computed(commonFacade.cookieContent);
    const ariaHidden = computed(() => {
      const openStatus =
        accessibilityFacade.hasDialogOpened() || cookieAlertVisibility.value;
      return openStatus.toString();
    });
    const informationPanelContent = ref({});

    watch(cookieContent, (currentCookieContent) => {
      cookieAlertVisibility.value = !!currentCookieContent;
    });

    if (
      !cookieAcceptedTime ||
      (cookieAcceptedTime &&
        isAfter(new Date(), addDays(new Date(cookieAcceptedTime), 30)))
    ) {
      localStorage.removeItem("nuszCookieAcceptedDate");
      cookieAlertVisibility.value = true;
    }

    const hideCookieAlert = () => {
      cookieAlertVisibility.value = false;
    };

    accessibilityFacade.resetOpenedDialogCount();

    commonFacade.getMenu();

    tabFocusSubscription.add(
      tabFocusService.getFocus().subscribe((target: string) => {
        (document.querySelector(target) as HTMLElement).focus();
      })
    );
    const getInformationPanelContent = () => {
      const keyValue =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        computed(commonFacade.keyValuePairs).value?.informationPanelCategoryId;
      const lang = currentLanguage.value as string;
      publicControllerApi
        .getInformationPanelContent(keyValue?.value as string, lang)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          informationPanelContent.value = res.data[0];
        });
    };

    onMounted(() => {
      getInformationPanelContent();
    });

    onUnmounted(() => {
      tabFocusSubscription.unsubscribe();
    });
    router.afterEach(() => {
      visibleRecaptchaLogo();
    });
    return {
      cookieAlertVisibility,
      hideCookieAlert,
      tenant,
      currentLanguage,
      ariaHidden,
      envName,
      informationPanelContent,
      isEmpty,
    };
  },
  metaInfo() {
    const lang = this.currentLanguage as string;
    const favicon = this.tenant?.icon?.url as string;
    const title = this.tenant?.title[lang] as string;
    const description = this.tenant?.description as string;
    return {
      title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
      link: [{ rel: "icon", href: favicon }],
      htmlAttrs: {
        lang,
      },
    };
  },
});


import { VExpansionPanelHeader } from "vuetify/lib";
import Vue from "vue";

interface IExpansionPanel {
  isActive: boolean;
}

interface IExpansionPanelHeader {
  expansionPanel: IExpansionPanel;
}

type ExpansionPanelHeaderComputedType = {
  expansionPanel?: IExpansionPanel;

  isActive: string;
};

export default Vue.extend<
  IExpansionPanelHeader,
  IExpansionPanelHeader,
  ExpansionPanelHeaderComputedType
>({
  name: "expansion-panel-header",
  extends: VExpansionPanelHeader,
  computed: {
    isActive(): string {
      return this.expansionPanel.isActive.toString();
    },
  },
});

















































































import { defineComponent, watch, ref, computed } from "@vue/composition-api";
import { EStickerTypeValue } from "@/core/dataTypes/types/sticker.interface";
import { isFuture } from "date-fns";

export default defineComponent({
  name: "StickerCard",
  props: {
    successfulness: Boolean,
    cartElement: Object,
    loading: Boolean,
    isAdHoc: Boolean,
    isValidSticker: Boolean,
    isDashboardStyle: Boolean,
  },
  setup(props, { emit }) {
    const isLocalLoading = ref(false);
    watch(
      () => props.cartElement,
      () => {
        isLocalLoading.value = false;
      }
    );

    watch(
      () => props.loading,
      (value) => {
        if (isLocalLoading.value) {
          isLocalLoading.value = value;
        }
      }
    );
    const isToday = computed(() =>
      isFuture(new Date(props.cartElement?.validityStart))
    );

    const onDocumentDownload = (highwayStickerPurchaseId: string) => {
      isLocalLoading.value = true;
      emit("onDocumentDownload", highwayStickerPurchaseId);
    };

    return {
      isToday,
      EStickerTypeValue,
      isLocalLoading,
      onDocumentDownload,
    };
  },
});

import { CMSSalesPointDetails } from "../dataTypes/cmsTypes";
import { SalesPointDetails } from "../dataTypes/types";
import { commonTransformer } from "./common.transformer";

const transformSalesPointDetails = (
  data: CMSSalesPointDetails
): SalesPointDetails => {
  const {
    id,
    locationCity,
    locationPostalCode,
    locationAddress,
    locationLatitude,
    locationLongitude,
    // hasBalanceTopping,
    // hasTicketPlanning,
    // hasTicketPurchase,
    name,
  } = data;

  const openingHours = commonTransformer.transformOpeningHours(
    data.openingHours
  );
  // console.log(openingHours);

  return {
    id,
    name,
    companyName: data.company,
    categoryName: data.category ? data.category : "",
    locationCountry: data.locationCountry ? data.locationCountry : "",
    locationCounty: data.locationCounty ? data.locationCounty : "",
    locationCity,
    ...(locationPostalCode && { locationPostalCode }),
    locationAddress,
    locationLatitude,
    locationLongitude,
    hasBalanceTopping: data.hasBalanceTopping ? data.hasBalanceTopping : "",
    hasTicketPlanning: data.hasTicketPlanning ? data.hasTicketPlanning : "",
    hasTicketPurchase: data.hasTicketPurchase ? data.hasTicketPurchase : "",
    ...(data.openingHours && {
      openingHours: openingHours,
    }),
  };
};

export const publicHugoSalesPointControllerTransformer = {
  transformSalesPointDetails,
};



































































































































import { defineComponent, PropType } from "@vue/composition-api";
import { SalesPointDetails } from "@/core/dataTypes/types";
import OpeningHours from "@/components/openingHours/OpeningHours.vue";

export default defineComponent({
  name: "SalesPointInformation",
  components: {
    OpeningHours,
  },
  props: {
    details: {
      type: Object as PropType<SalesPointDetails>,
      required: true,
    },
    tenant: {
      type: String,
      required: true,
    },
    tile: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    return {
      nudTenant: process.env.VUE_APP_TENANT,
    };
  },
});












import { defineComponent, computed, Ref } from "@vue/composition-api";
import { salesPointsFacade } from "@/store/modules/salesPoints/salesPoints.facade";
import { useRoute } from "@/router";
import { Content } from "@/core/dataTypes/types";
import SalesPointsMap from "@/views/salesPoints/components/salesPointsMap/SalesPointsMap.vue";

export default defineComponent({
  name: "HuGoSalesPoints",
  components: {
    SalesPointsMap,
  },
  setup() {
    const route = useRoute();
    const content: Ref<Content[] | undefined> = computed(
      salesPointsFacade.content
    );
    const tenant = process.env.VUE_APP_TENANT;

    salesPointsFacade.resetModule();
    salesPointsFacade.getContent(route.value.slug as string);

    return {
      content,
      tenant,
    };
  },
  metaInfo() {
    const actualContent = this.content
      ? (this.content as Content[])[0]
      : undefined;
    const title =
      (actualContent?.seoTitle as string) ?? (actualContent?.title as string);
    const description = actualContent?.seoLead as string;
    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
      ],
    };
  },
});

import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { commonFacade } from "@/store/modules/common/common.facade";
import i18n from "@/plugins/i18n";

let vueInstance: Vue;

// reCAPTCHA init
export const initRecaptcha = (vm: Vue): void => {
  vueInstance = vm;
  visibleRecaptchaLogo();
};
// reCAPTCHA options
const reCaptchaOptions = {
  siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
  loaderOptions: {
    autoHideBadge: false,
  },
};
// reCAPTCHA token
export const getRecaptchaToken = (action: string): Promise<string> => {
  return vueInstance.$recaptcha(action);
};

// reCAPTCHA logo hide
export const hideRecaptchaLogo = (): void => {
  vueInstance.$recaptchaInstance.hideBadge();
};

// reCAPTCHA logo show
export const showRecaptchaLogo = (): void => {
  vueInstance.$recaptchaInstance.showBadge();
};

// reCAPTCHA visible handler
export const visibleRecaptchaLogo = (): void => {
  const isMatchUrl =
    `/${commonFacade.currentLanguage()}/${i18n.t("ROUTER.QUERY_STICKER")}` ===
    vueInstance.$route.path;
  vueInstance.$recaptchaLoaded().then(() => {
    if (isMatchUrl) {
      showRecaptchaLogo();
    } else {
      hideRecaptchaLogo();
    }
  });
};

Vue.use(VueReCaptcha, reCaptchaOptions);

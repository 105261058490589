import { publicControllerApi } from "@/core/api/publicController.api";
import { Content } from "@/core/dataTypes/types";
import { AppState } from "@/store";
import { cloneDeep } from "lodash/fp";
import { Module } from "vuex";

export interface CustomState {
  content: Content[];
  contentLoading: boolean;
}

export const initialCustomState: CustomState = {
  content: [],
  contentLoading: false,
};

export const customModule: Module<CustomState, AppState> = {
  namespaced: true,
  state: initialCustomState,
  mutations: {
    contentRequest: (state) => {
      state.contentLoading = true;
    },
    contentSuccess: (state, payload: Content[]) => {
      state.content = payload;
      state.contentLoading = false;
    },
    updateContentPaths: (state) => {
      state.content.forEach((c) => {
        c.menuPath = `${c.menuPath}/${c.slug}`;
        c.subContent = true;
      });
    },
    contentFail: (state) => {
      state.contentLoading = false;
    },
    resetModule: (state) => {
      Object.assign(state, cloneDeep(initialCustomState));
    },
  },
  actions: {
    resetModule: ({ commit }) => {
      commit("resetModule");
    },
    getContent: ({ commit }, payload) => {
      commit("contentRequest");
      return publicControllerApi
        .getMenuContentByPublicRoute(
          payload.slug,
          payload.subContent,
          payload.id
        )
        .then((response) => {
          commit("contentSuccess", response);
        })
        .catch((error) => {
          commit("contentFail");
          throw Error(error);
        });
    },
    updateContentPaths: ({ commit }) => {
      commit("updateContentPaths");
    },
  },
};

import {
  CMSMapPoint,
  CMSSalesPointDetails,
  CMSSalesPointsRequest,
} from "../dataTypes/cmsTypes";
import { SalesPointDetails } from "../dataTypes/types";
import httpService from "../services/http.service";
import { publicHugoSalesPointControllerTransformer } from "../transformers/publicHugoSalesPointController.transformer";

const getSalesPoints = (
  tenantSlug: string,
  requestData: CMSSalesPointsRequest,
  language: string
): Promise<CMSMapPoint[]> =>
  httpService
    .post<CMSMapPoint[]>(
      `/api/hugoSalesPoints/public/${tenantSlug}/sales-points`,
      requestData,
      {
        params: { lang: language },
      }
    )
    .then((response) => response.data);

const getSalesPointDetails = (
  tenantSlug: string,
  id: string
): Promise<SalesPointDetails> =>
  httpService
    .get<CMSSalesPointDetails>(
      `/api/hugoSalesPoints/public/${tenantSlug}/sales-points/${id}`
    )
    .then((response) =>
      publicHugoSalesPointControllerTransformer.transformSalesPointDetails(
        response.data
      )
    );

export const publicHugoSalesPointControllerApi = {
  getSalesPoints,
  getSalesPointDetails,
};

import { Content } from "@/core/dataTypes/types";
import store from "@/store";

const content = (): Content[] | undefined => store.state.custom?.content;
const contentLoading = (): boolean | undefined =>
  store.state.custom?.contentLoading;
const getContent = (
  slug: string,
  subContent: boolean,
  id: string
): Promise<void> =>
  store.dispatch("custom/getContent", { slug, subContent, id });

const resetModule = (): Promise<void> => store.dispatch("custom/resetModule");
const updateContentPaths = (): Promise<void> =>
  store.dispatch("custom/updateContentPaths");

export const customFacade = {
  content,
  contentLoading,
  getContent,
  resetModule,
  updateContentPaths,
};

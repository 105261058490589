import { publicControllerApi } from "@/core/api/publicController.api";
import { CMSTagType } from "@/core/dataTypes/cmsTypes";
import { AppState } from "@/store";
import { Module } from "vuex";
import { commonFacade } from "../common/common.facade";

export interface TagState {
  tags: CMSTagType[];
  tagsLoading: boolean;
}

export const initialTagState: TagState = {
  tags: [] as CMSTagType[],
  tagsLoading: false,
};

export const tagModule: Module<TagState, AppState> = {
  namespaced: true,
  state: initialTagState,
  mutations: {
    tagsRequest: (state) => {
      state.tagsLoading = true;
    },
    tagsSuccess: (state, payload: CMSTagType[]) => {
      state.tags = payload;
      state.tagsLoading = false;
    },
    tagsFail: (state) => {
      state.tagsLoading = false;
    },
  },
  actions: {
    getTags: ({ commit }) => {
      commit("tagsRequest");
      const language = commonFacade.currentLanguage();
      if (!language) {
        commit("tagsFail");
        throw new Error("NO_LANGUAGE_SELECTED");
      }
      return publicControllerApi
        .getTags(language, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("tagsSuccess", response);
        })
        .catch((error) => {
          commit("tagsFail");
          throw Error(error);
        });
    },
  },
};

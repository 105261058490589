

























































































































































































import { computed, defineComponent, ref } from "@vue/composition-api";
import Search from "@/components/search/Search.vue";
import LanguageSelector from "@/components/languageSelector/LanguageSelector.vue";
import SocialIconList from "@/components/socialIconList/SocialIconList.vue";
import LoginMenu from "@/components/header/navigation/components/loginMenu/LoginMenu.vue";
import DesktopMenu from "@/components/header/navigation/desktop/DesktopMenu.vue";
import MobileMenu from "@/components/header/navigation/mobile/MobileMenu.vue";
import { commonFacade } from "@/store/modules/common/common.facade";
import router from "@/router";
import { pageOnBaseLanguage } from "@/core/utils/languageHandler.util";

export default defineComponent({
  name: "Header",
  components: {
    Search,
    LanguageSelector,
    SocialIconList,
    LoginMenu,
    DesktopMenu,
    MobileMenu,
  },
  setup() {
    const mobileSearchBarVisibile = ref<boolean>(false);
    const overlay = ref<boolean>(false);
    const tenant = computed(commonFacade.tenant);
    const tenantLoading = computed(commonFacade.tenantLoading);
    const hideSearch = computed(pageOnBaseLanguage);
    const currentLanguage = computed(commonFacade.currentLanguage);
    const localizedLogo = computed(() => {
      let currentLanguageIndex = tenant.value?.localization
        .map((tl) => tl.languageCode)
        .indexOf(currentLanguage.value);
      if (!currentLanguageIndex) {
        currentLanguageIndex != 0
          ? (currentLanguageIndex = -1)
          : (currentLanguageIndex = 0);
      }
      if (!tenant.value?.localization[currentLanguageIndex]) {
        return null;
      }

      return tenant.value?.localization[currentLanguageIndex].localizedLogo;
    });

    const mobileSearchBar = () => {
      mobileSearchBarVisibile.value = !mobileSearchBarVisibile.value;
      overlay.value = !overlay.value;
    };

    const handleLanguageSelection = (languageCode: string) => {
      commonFacade.setCurrentLanguage(languageCode);
      router.push(`/${languageCode}`).then(() => location.reload());
    };

    return {
      overlay,
      mobileSearchBarVisibile,
      mobileSearchBar,
      tenant,
      tenantLoading,
      handleLanguageSelection,
      hideSearch,
      currentLanguage,
      localizedLogo,
    };
  },
});

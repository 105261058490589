
































import { computed, defineComponent } from "@vue/composition-api";
import BaseDialog from "@/components/dialog/baseDialog/BaseDialog.vue";
import { SalesPointDetails } from "@/core/dataTypes/types";
import { dialogCloseEmitter } from "@/core/utils/dialog/dialogEmitter";
import SalesPointInformation from "@/views/salesPoints/components/salesPointInformation/SalesPointInformation.vue";
import { salesPointsFacade } from "@/store/modules/salesPoints/salesPoints.facade";

export default defineComponent({
  name: "SalesPointInformationDialog",
  components: {
    BaseDialog,
    SalesPointInformation,
  },
  props: {
    tenant: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const details = computed<SalesPointDetails | undefined>(
      salesPointsFacade.salesPointDetails
    );
    const detailsLoading = computed<boolean | undefined>(
      salesPointsFacade.salesPointDetailsLoading
    );

    const closeDialog = () => {
      dialogCloseEmitter(ctx);
    };

    return {
      closeDialog,
      details,
      detailsLoading,
    };
  },
});

import { commonFacade } from "@/store/modules/common/common.facade";
import { NavigationGuardNext, Route } from "vue-router";

export const languageGuard = (
  to: Route,
  from: Route,
  next: NavigationGuardNext<Vue>
): void => {
  const lang = to.params.lang;
  if (commonFacade.currentLanguage() !== lang) {
    commonFacade.setCurrentLanguage(lang);
  }

  if (commonFacade.languageCodes().includes(lang)) {
    next();
  } else {
    next(process.env.VUE_APP_I18N_LOCALE);
  }
};




























import { defineComponent, ref } from "@vue/composition-api";
import router from "@/router";
import { searchFacade } from "@/store/modules/search/search.facade";

export default defineComponent({
  name: "SearchComponent",
  props: {
    id: {
      type: String,
      default: "header",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const searchText = ref<string>("");

    const clear = () => {
      searchText.value = "";
    };

    const submit = () => {
      router
        .push({
          name: "SearchListPage",
          query: {
            page: "1",
            filters: "",
            keyword: searchText.value ?? "",
          },
        })
        .catch(() => {
          searchFacade.getSearchContentListByKeyword(1, searchText.value);
        })
        .finally(clear);
    };

    return {
      searchText,
      submit,
      clear,
    };
  },
});
























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "SkipLinkComponent",
  setup() {
    const onSkip = () => {
      const mainElement = document.querySelector("#main");
      if (mainElement) {
        const focusable = mainElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
        const firstFocusable = focusable[0] as HTMLElement;
        firstFocusable.focus();
      }
    };
    return {
      onSkip,
    };
  },
});

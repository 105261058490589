























import { defineComponent, Ref, ref } from "@vue/composition-api";
import { publicControllerApi } from "@/core/api/publicController.api";
import { MediaCollectionItem } from "@/core/dataTypes/types";

export default defineComponent({
  name: "DocumentList",
  props: {
    dataListId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const dataList: Ref<MediaCollectionItem[]> = ref([]);
    const caption = ref("");

    publicControllerApi
      .getMediaCollection(process.env.VUE_APP_TENANT, props.dataListId)
      .then((response) => {
        console.log("response", response);
        caption.value = response.name;
        dataList.value = response.items;
      });

    return {
      caption,
      dataList,
    };
  },
});

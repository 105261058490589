



























import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import router, { useRoute } from "@/router";
import { customFacade } from "@/store/modules/custom/custom.facade";
import ContentMiniCard from "@/components/contentMiniCard/ContentMiniCard.vue";
import ContentPage from "@/components/contentPage/ContentPage.vue";
import { Content } from "@/core/dataTypes/types";

export default defineComponent({
  name: "CustomSlug",
  components: {
    ContentMiniCard,
    ContentPage,
  },
  setup() {
    customFacade.resetModule();
    const route = useRoute();
    const title = ref("");
    const contentTitle = ref("");
    const contents = computed(() => {
      if ((customFacade.content() as Content[]).length > 1) {
        customFacade.updateContentPaths();
      }
      getFirstContentTitle(customFacade.content() as Content[]);
      return customFacade.content();
    });
    const contentLoading = computed(customFacade.contentLoading);

    const getFirstContentTitle = (contents: Content[]) => {
      contents.every((content) => {
        if (content.contentCategoryName) {
          contentTitle.value = content.contentCategoryName;
          return false;
        }
        return true;
      });
    };

    const getContent = () => {
      customFacade
        .getContent(
          route.value.slug as string,
          route.value.query.subContent === "true",
          route.value.query.id as string
        )
        .catch(() => {
          router.push({ name: "Home" });
        });
    };
    getContent();

    watch(route, (curr, prev) => {
      if (curr.path !== prev.path) {
        customFacade.resetModule();
        getContent();
      }
    });

    return {
      route,
      title,
      contents,
      contentLoading,
      contentTitle,
    };
  },
  metaInfo() {
    const contents = this.contents as Content[];
    if (contents?.length !== 1) {
      return {};
    }
    const title: string =
      (contents[0]?.seoTitle as string) ?? (contents[0]?.title as string);
    const description = contents[0]?.seoLead as string;
    const pictureUrl = contents[0]?.cover?.thumbnails?.landscape[1] as string;

    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:title", property: "og:title", content: title },
        {
          vmid: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: pictureUrl,
        },
      ],
    };
  },
});





































import { EMenuTypes } from "@/core/dataTypes/enums";
import { computed, defineComponent, ref, PropType } from "@vue/composition-api";
import MenuList from "@/components/header/navigation/components/menuList/MenuList.vue";
import { MenuItem } from "@/core/dataTypes/types";

export default defineComponent({
  name: "DropDownMenu",
  components: {
    MenuList,
  },
  props: {
    menuData: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
  },
  setup(props, { root }) {
    const routeParams = computed(() => root.$route.params);
    const menu = ref<boolean>();
    const clickedOnMenu = (value: boolean) => {
      menu.value = value;
    };
    const closeMenu = () => {
      menu.value = false;
    };

    return {
      EMenuTypes,
      menu,
      clickedOnMenu,
      closeMenu,
      routeParams,
    };
  },
});









import { computed, defineComponent, watch } from "@vue/composition-api";
import { newsFacade } from "@/store/modules/news/news.facade";
import ContentPage from "@/components/contentPage/ContentPage.vue";
import { useRoute } from "@/router";

export default defineComponent({
  name: "NewsItemPage",
  components: {
    ContentPage,
  },
  setup() {
    const route = useRoute();
    const newsContent = computed(newsFacade.newsContent);

    newsFacade
      .newsContentReset()
      .then(() => newsFacade.getNewsContent(route.value.params.contentSlug));

    watch(route, (current, prev) => {
      if (current.path !== prev.path) {
        newsFacade
          .newsContentReset()
          .then(() => newsFacade.getNewsContent(current.params.contentSlug));
      }
    });

    return {
      newsContent,
    };
  },
  metaInfo() {
    const title: string =
      (this.newsContent?.seoTitle as string) ??
      (this.newsContent?.title as string);
    const description = this.newsContent?.seoLead as string;
    const pictureUrl = this.newsContent?.cover?.thumbnails
      ?.landscape[1] as string;

    return {
      titleTemplate: `${title} | %s`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description,
        },
        { vmid: "og:url", property: "og:url", content: window.location.href },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:title", property: "og:title", content: title },
        {
          vmid: "og:description",
          property: "og:description",
          content: description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: pictureUrl,
        },
      ],
    };
  },
});

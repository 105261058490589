import { publicControllerApi } from "@/core/api/publicController.api";
import {
  Content,
  ContentsRequestModel,
  ContentsResponse,
  FilterModel,
  SortRequestModel,
} from "@/core/dataTypes/types";
import router from "@/router";
import { AppState } from "@/store";
import { Module } from "vuex";

export interface NewsState {
  newsContent: Content;
  newsContentLoading: boolean;
  newsContentList: Content[];
  newsContentListLoading: boolean;
  newsContentListPageCount: number;
  newsListPageContent: Content;
  newsListPageContentLoading: boolean;
}

export const initialNewsState: NewsState = {
  newsContent: {} as Content,
  newsContentLoading: false,
  newsContentList: [] as Content[],
  newsContentListLoading: false,
  newsContentListPageCount: 1,
  newsListPageContent: {} as Content,
  newsListPageContentLoading: false,
};

const PAGE_SIZE = 8;

export const newsModule: Module<NewsState, AppState> = {
  namespaced: true,
  state: initialNewsState,
  mutations: {
    newsContentRequest: (state) => {
      state.newsContentLoading = true;
    },
    newsContentSuccess: (state, payload: Content) => {
      state.newsContent = payload;
      state.newsContentLoading = false;
    },
    newsContentFail: (state) => {
      state.newsContentLoading = false;
    },
    newsContentReset: (state) => {
      state.newsContent = initialNewsState.newsContent;
      state.newsContentLoading = initialNewsState.newsContentLoading;
    },
    newsContentListRequest: (state) => {
      state.newsContentListLoading = true;
    },
    newsContentListSuccess: (state, payload: ContentsResponse) => {
      state.newsContentList = payload.data;
      state.newsContentListLoading = false;
      state.newsContentListPageCount = Math.ceil(
        payload.totalNumberOfItems / PAGE_SIZE
      );
    },
    newsContentListFail: (state) => {
      state.newsContentListLoading = false;
    },
    newsListPageContentRequest: (state) => {
      state.newsListPageContentLoading = true;
    },
    newsListPageContentSuccess: (state, payload: Content) => {
      state.newsListPageContent = payload;
      state.newsListPageContentLoading = false;
    },
    newsListPageContentFail: (state) => {
      state.newsListPageContentLoading = false;
    },
  },
  actions: {
    getNewsContent: ({ commit }, slug: string) => {
      commit("newsContentRequest");
      return publicControllerApi
        .contentBySlug(process.env.VUE_APP_TENANT, slug)
        .then((response) => {
          commit("newsContentSuccess", response);
        })
        .catch((error) => {
          commit("newsContentFail");
          router.push({ name: "Home" });
          throw Error(error);
        });
    },
    newsContentReset: ({ commit }) => {
      commit("newsContentReset");
    },
    getNewsContentList: (
      { commit, rootState },
      { page, tagIds }: { page: number; tagIds?: string[] }
    ) => {
      commit("newsContentListRequest");
      const keyValuePairs = rootState.common?.keyValuePairs;
      const payload: ContentsRequestModel<SortRequestModel, FilterModel> = {
        take: PAGE_SIZE,
        skip: PAGE_SIZE * (page - 1),
        sort: {
          visibilityFrom: "DESC",
        },
        filter: {
          contentCategoryId: keyValuePairs?.newsCategory.value as string,
          languageCode: rootState.common?.currentLanguage as string,
          tags: tagIds?.length ? tagIds : undefined,
        },
        contentBodyReturn: false,
      };
      return publicControllerApi
        .getContents(payload, process.env.VUE_APP_TENANT)
        .then((response) => {
          commit("newsContentListSuccess", response);
        })
        .catch((error) => {
          commit("newsContentListFail");
          throw Error(error);
        });
    },
    getNewsListPageContent: ({ commit }, slug: string) => {
      commit("newsListPageContentRequest");
      return publicControllerApi
        .getMenuContentByPublicRoute(slug)
        .then((response) => {
          commit("newsListPageContentSuccess", response[0]);
        })
        .catch((error) => {
          commit("newsListPageContentFail");
          throw Error(error);
        });
    },
  },
};

import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import Home from "@/views/home/Home.vue";
import SimpleRouterView from "@/components/simpleRouterView/SimpleRouterView.vue";
import NewsListPage from "@/views/news/NewsList.vue";
import NewsItemPage from "@/views/news/item/NewsItem.vue";
import SearchListPage from "@/views/search/SearchList.vue";
import CustomerService from "@/views/customerService/CustomerService.vue";
import CustomSlug from "@/views/CustomSlug.vue";
import StickerQueryPage from "@/views/stickerQuery/StickerQuery.vue";
import FAQPage from "@/views/FAQ/FAQ.vue";
import HuGoSalesPoints from "@/views/salesPoints/HuGoSalesPoints/HuGoSalesPoints.vue";
import EmatricaSalesPoints from "@/views/salesPoints/EmatricaSalesPoints/EmatricaSalesPoints.vue";
import i18n from "@/plugins/i18n";
import { languageGuard } from "@/core/guards/language.guard";
import { computed, ComputedRef } from "@vue/composition-api";
import { EFaqTabTypes } from "@/core/dataTypes/enums";
import { Position } from "vue-router/types/router";
import { commonFacade } from "@/store/modules/common/common.facade";
import { tabFocusService } from "@/core/services/tabFocus.service";
import { accessibilityFacade } from "@/store/modules/accessibility/accessibility.facade";
import { memoizedSlugs } from "@/core/utils/route.util";
import { RouteWithSlug } from "@/core/dataTypes/types";

const splitPath = window.location.pathname.split("/");
const pathLang = splitPath.find((i: string) => i.length === 2);

if (pathLang && pathLang !== commonFacade.currentLanguage()) {
  i18n.locale = pathLang;
  commonFacade.setCurrentLanguage(pathLang);
} else if (!pathLang) {
  i18n.locale = process.env.VUE_APP_I18N_LOCALE;
  commonFacade.setCurrentLanguage(process.env.VUE_APP_I18N_LOCALE);
} else {
  i18n.locale = pathLang;
}

Vue.use(VueRouter);
// HU: hu/e-matrica/matrica-lekerdezes
// EN: en/e-vignette/sticker-query
// DE: de/e-vignette/aufkleber-abfrage
const routes: Array<RouteConfig> = [
  {
    path: "/:lang",
    component: SimpleRouterView,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: `${i18n.t("ROUTER.CUSTOMER_SERVICE")}`,
        name: "CustomerService",
        component: CustomerService,
      },
      {
        path: `${i18n.t("ROUTER.QUERY_STICKER")}`,
        name: "StickerQueryPage",
        component: StickerQueryPage,
      },
      {
        path: `${i18n.t("ROUTER.NEWS")}`,
        component: SimpleRouterView,
        children: [
          {
            path: "",
            name: "NewsListPage",
            component: NewsListPage,
          },
          {
            path: ":contentSlug",
            name: "NewsItemPage",
            component: NewsItemPage,
          },
        ],
      },
      {
        path: `${i18n.t("ROUTER.SEARCH")}`,
        name: "SearchListPage",
        component: SearchListPage,
      },
      {
        path: `${i18n.t("ROUTER.FAQ")}`,
        name: "FAQPage",
        component: FAQPage,
      },
      {
        path: `${i18n.t("ROUTER.FAQ_EMATRICA")}`,
        name: "EmatricaFAQPage",
        props: {
          activeTab: EFaqTabTypes.E_MATRICA,
        },
        component: FAQPage,
      },
      {
        path: `${i18n.t("ROUTER.FAQ_HUGO")}`,
        name: "HuGoFAQPage",
        props: {
          activeTab: EFaqTabTypes.E_UTDIJ,
        },
        component: FAQPage,
      },
      {
        path: `${i18n.t("ROUTER.EMATRICA_SALES_POINTS")}`,
        name: "EmatricaSalesPoints",
        component: EmatricaSalesPoints,
      },
      {
        path: `${i18n.t("ROUTER.HUGO_SALES_POINTS")}`,
        name: "HuGoSalesPoints",
        component: HuGoSalesPoints,
      },
      {
        path: "*",
        name: "CustomSlug",
        component: CustomSlug,
      },
    ],
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to: Route, from: Route, savedPosition: Position | void) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        offset: { y: 200, x: 0 },
      };
    } else if (savedPosition) {
      return savedPosition;
    } else if (from.path === to.path) {
      // in-page tab or accordion navigation - do not scroll
      return null;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

router.beforeEach(languageGuard);

const routeData = Vue.observable<{
  route: RouteWithSlug;
}>({ route: {} as RouteWithSlug });

const cachedSlugs = memoizedSlugs();

router.afterEach((to: Route, from: Route) => {
  routeData.route = { ...to, slug: cachedSlugs(to.path) };

  if (to.name !== from.name) {
    tabFocusService.setFocus("#app");
  }
  accessibilityFacade.resetOpenedDialogCount();
});

export const useRoute = (): ComputedRef<RouteWithSlug> => {
  return computed(() => routeData.route);
};

export default router;
